<template>
  <div class="page mt-5">
    <v-row>
      <v-row><h4 class="ma-1 mb-3 mt-5">Заявления</h4></v-row>
      <v-row style="width:100%">
        <v-data-table
          style="width:100%"
          :headers="headers"
          :items="requests"
          :page="currentPage"
          :server-items-length="totalRequests"
          :items-per-page="requestsPerPage"
          loading-text="Загрузка... Пожалуйста подождите"
          class="elevation-1 mt-3"
          @update:page="pageUpdateFunction"
          @update:items-per-page="itemsPerPageUpdate"
        >
        </v-data-table>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id'
        },
        { text: 'ФИО консультанта', value: 'consultant' },
        { text: 'Номер аттестата', value: 'attestat_number' },
        { text: 'Телефон', value: 'phone' },
        { text: 'email', value: 'email' },
        { text: 'Дата', value: 'date_added' },
        { text: 'Тип', value: 'type' },
        { text: 'Группа', value: 'group' },
        { text: 'Статус', value: 'consultant_status' },
      ],
      isLoadingSave: false,
      currentPage: 1,
      requestsPerPage: 10,
      requests: [],
      totalRequests: 0
    }
  },
  computed: {
    ...mapState('app', ['user'])
  },
  mounted() {
    this.getDataFromApi()
  },
  beforeDestroy() {
  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess', 'GET_REQUESTS']),
    filterObject(item, queryText, itemText) {
      return (
        item.inn.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1 ||
        item.fio.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    pageUpdateFunction(newPageNumber) {
      this.currentPage = newPageNumber
      this.getDataFromApi()
    },
    itemsPerPageUpdate(value) {
      this.requestsPerPage = value
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true
      const itemsPerPage = this.requestsPerPage

      this.GET_REQUESTS({q: this.requestsPerPage, page: this.currentPage})
        .then(response => {
          this.totalRequests = response.total
          this.requests = response.items
          this.loading = false
        }).catch(error => {
          console.log(error)
        })
    },
    path(val) {
      location.href = val
    },
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
