<template>
  <div class="page mt-5">
    <v-row v-if="false">
      <v-row>
        <h4 class=" mb-3 mt-5">Действия перед началом работы в системе</h4>
      </v-row>
      <v-row class="mb-6 ">
        <v-card
          color=" lighten-1"
        >
          <v-list-item five-line>
            <v-list-item-content>
              <!-- <div class="overline mb-4" >
                Заявление
              </div> -->
              <small>
                1. Вы подали заявление на подготовку на аккредитацию, скачайте заявление, подпишите и прекрепите в
                систему в поле ниже

                <b> </b>
              </small>

              <v-btn
                small
                label
                class="mt-1"
                max-width="150px"
                @click="download_zayav()"
              >
                Скачать заявление
              </v-btn>
              <v-btn
                small
                label
                class="mt-1 ml-2"
                max-width="200px"
                @click="download_zayav()"
              >
                Загрузить подписанный скан
              </v-btn>

              <small class="mt-2">
                2. В информационных целях вы можете ознакомиться с регламентом партнерства с Налоговой Палатой
              </small>

              <v-btn
                small
                label
                href="https://palata-nk.ru/education/kak-stat-nalogovym-konsultantom/"
                target="_blank"
                class="mt-1"
                max-width="150px"
              >
                Ознакомиться
              </v-btn>

            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-row>

    </v-row>
    <v-row v-if="adverts!=[]">
      <template v-for="advert in adverts" cols="12">
        <v-card
          class="mt-2 pa-2"
          width="100%"
        >
          <v-card-title>
            {{ advert.title }}
          </v-card-title>
          <v-card-subtitle>
            <v-icon small> mdi-calendar</v-icon>
            {{ advert.created }}
          </v-card-subtitle>
          <v-card-text>
            {{ advert.text }}
          </v-card-text>
          <v-card-actions>

            <v-btn
              v-if="advert.link"
              color="rgb(4, 162, 124)"
              label
              outlined
              @click="path(advert.link)"
            >
              Подробнее
            </v-btn>

          </v-card-actions>
        </v-card>
      </template>
    </v-row>
    <v-row>

      <v-row>
        <h4 class="ma-1 mb-3 mt-10">Образовательные группы

          <v-chip
            label
            color="rgb(4, 162, 124)"
            dark
            small
            class="mt-1"
            @click="dialog = true"
          >
            Создать новый набор
          </v-chip>
        </h4>

      </v-row>

      <v-row v-for="(status, index) in group_statuses" :key="index" style="width:100%">
        <h6 class="ml-1 mt-4">{{ status }}</h6>
        <v-data-table
          style="width:100%"
          :headers="headers"
          :items="all_groups[status]"
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
          class="elevation-1 mt-3"
        >
          <template v-slot:item.status="{ item }">
            <v-chip
              label
              outlined
              @click="path(&quot;/listgroup?id=&quot;+item.id)"
              >
              Подробнее
            </v-chip>
          </template>
        </v-data-table>
      </v-row>

    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Новая группа</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="edu_form"
                    label="Форма"
                    :items="[&quot;Онлайн&quot;, &quot;Офлайн&quot;, &quot;Дневная&quot;, &quot;Комбинированная&quot;, &quot;Вечерняя&quot;, &quot;Дистанционная&quot;, &quot;Выходной день&quot;]"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-select
                    v-model="edu_level"
                    label="Уровень подготовки"
                    :items="[&quot;Повышение квалификации&quot;, &quot;Подготовка&quot;]"
                    @change="changeEduLevel"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="program"
                    :items="edu_programs"
                    :disabled="edu_level == ''"
                    label="Программа*"
                    item-text="name"
                    return-object
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="price"
                    label="Стоимость*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="manager"
                    label="Менеджер*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ed_hours"
                    label="Количество часов*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="menu_start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_start"
                        label="Дата начала*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_start"
                      @input="menu_start_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="menu_end_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_end"
                        label="Дата окончания*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_end"
                      @input="menu_end_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <small>*Обязательные поля</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="dialog = false"
            >
              Закрыть
            </v-btn>
            <v-btn
              outlined
              color="success"
              @click="add_group()"
            >
              Добавить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '@/router'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Название программы', value: 'program' },
        { text: 'Форма', value: 'form' },
        { text: 'Дата начала', value: 'date_start' },
        { text: 'Дата окончания', value: 'date_end' },
        { text: 'Стоимость', value: 'value' },
        { text: 'Дата экзамена', value: 'exam_date' },

        { text: 'Кол-во учеников', value: 'consultants_count' },
        { text: 'Кол-во часов', value: 'ed_hours' },
        { text: '', value: 'status' }

      ],
      groups: [
        {
          name: 'Группа 3',
          calories: 'активная',
          fat: 'Налоговое консультирование 360',
          carbs: 24,
          protein: 4.0,
          iron: '1%'
        }

      ],
      currentPage: 1,
      groupsPerPage: 10,
      totalGroups: 0,
      date_start: '',
      date_end: '',
      menu_start_date: false,
      menu_end_date: false,
      dialog: false,
      loading: false,
      group_number: '',
      manager: '',
      all_edu_programs: [],
      edu_programs: [],
      program: '',
      edu_form: '',
      edu_level: '',
      price: '',
      ed_hours: '',
      group_statuses: [],
      all_groups: {},
      adverts: [],
      ORG_INFO: null
    }
  },
  computed: {
    ...mapState('app', ['user'])
  },
  watch: {
    options: {
      handler() {
      },
      deep: true
    }
  },
  mounted() {
    this.GET_ORGANISATION_INFO().then(
      (res) => {
        if (!res.user.isPersonalFilled) {
          // не заполнены персональные данные
          router.push({ name: 'orgsettings' })
        } else if (!res.user.is_all_docs_uploaded) {
          // не загружены документы
          router.push({ name: 'org_docs' })
        }
        this.ORG_INFO = res.user

        this.getEducationPrograms()
        this.getGroups()
        this.getAdverts()
      }
    ).catch(
      (error) => console.log(error)
    )
  },
  beforeDestroy() {

  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess',
      'CREATE_GROUP', 'GET_PROGRAMS', 'GET_GROUPS', 'GET_ADVERTS',
      'GET_ORGANISATION_INFO']),
    getAdverts() {
      this.GET_ADVERTS().then((response) => {
        this.adverts = response.items
      }).catch((error) => {
        console.log(error)
      })
    },
    getGroups() {
      this.loading = true
      this.GET_GROUPS()
        .then((response) => {
          this.group_statuses = response.keys
          this.all_groups = response.items
          this.loading = false
        }).catch((error) => {
          console.log(error)
        })
    },
    getEducationPrograms() {
      this.GET_PROGRAMS({ id: localStorage.getItem('uid'), online: true })
        .then((response) => {
          this.all_edu_programs = response.items
          this.loading = false
        }).catch((error) => {
          console.log(error)
        })
    },
    download_zayav() {
      window.open('https://atknin.com/media/vfpg_1.odt', '_blank')
    },
    add_group() {
      this.dialog = false
      console.log(this.program)
      this.CREATE_GROUP(this.get_params()).then(
        (response) => {
          if (response.data.message === 'success') {
            this.path('/listgroup?id=' + response.data.group_id)
          }
        }).catch(
        (error) => console.log(error)
      )

    },
    get_params() {
      return {
        data: {
          manager: this.manager,
          date_end: this.date_end,
          date_start: this.date_start,
          program: this.program.id,
          edu_form: this.edu_form,
          price: this.price,
          ed_hours: this.ed_hours
        }
      }
    },
    getColor(status) {
      return 'green'
    },
    path(val) {
      location.href = val
    },
    parse(cards, listId) {
      return cards.filter((card) => card.l === listId).sort((a, b) => {
        if (a.votes.length < b.votes.length) return 1
        if (a.votes.length > b.votes.length) return -1

        return 0
      })
    },
    changeEduLevel(value) {
      let level = ''

      if (value === 'Подготовка') {
        level = 'start'
      } else {
        level = 'levelup'
      }
      this.edu_programs = this.all_edu_programs.filter(
        (program) => program.level === level
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
