<template>
  <div class="page mt-5">
    <v-row><h4 class="ma-1 mb-3 mt-5">Архив</h4></v-row>
    <v-row>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="search"
          label="ФИО, номер аттестата"
          prepend-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="2"
      >
        Дата выпуска:
      </v-col>
      <v-col
        cols="4"
      >
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="date_start"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_start"
              label="ОТ (включительно)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_start"
            no-title
            scrollable
            locale="ru"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu2 = false"
            >
              Отмена
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu2.save(date_start)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="4"
      >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date_end"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date_end"
              label="ДО (включительно)"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date_end"
            no-title
            scrollable
            locale="ru"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Отмена
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date_end)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="group"
          :items="groups"
          item-text="name"
          item-value="id"
          label="Группы"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="program"
          :items="programs"
          item-text="name"
          item-value="id"
          label="Программы"
          outlined
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="2">
        <v-btn
          fab
          dark
          small
          @click="destroyFilters()"
        >
          <v-icon>
            mdi-close-circle-outline
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          @click="getDataFromApi()"
        >
          применить фильтры
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-row style="width:100%">
        <v-data-table
          style="width:100%"
          :headers="headers"
          :items="requests"
          item-key="id"
          :page="currentPage"
          :server-items-length="totalRequests"
          :items-per-page="requestsPerPage"
          :footer-props="{
            itemsPerPageOptions: requestsPerPageList,
            itemsPerPageText: 'кол-во на стр.:',
            showCurrentPage: true,
            pageText: `всего консультантов: ${totalRequests}`,
            pagination: {
              page: currentPage,
              itemsPerPage: 1,
              pageStart: 0,
              pageStop: 1,
              pageCount: 0,
              itemsLength: 4
            }
          }"
          :group-by="['group.name', 'program']"
          loading-text="Загрузка... Пожалуйста подождите"
          class="elevation-1 mt-3"
          @update:page="pageUpdateFunction"
          @update:items-per-page="itemsPerPageUpdate"
        >
          <template v-slot:item.consultant="{ item }">
            {{ item.consultant.last_name }} {{ item.consultant.name }} {{ item.consultant.middle_name }}
          </template>
          <template v-slot:group.header="{ group, isOpen, toggle }">
            <v-icon
              v-if="isOpen"
              @click="toggle()"
            >
              mdi-minus
            </v-icon>
            <v-icon
              v-else
              @click="toggle()"
            >
              mdi-plus
            </v-icon>
            {{ group }}
            <v-btn
              small
            >
              выбрать
              <v-icon>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
          groupable: true
        },
        { text: 'ФИО консультанта', value: 'consultant' },
        { text: 'Номер аттестата', value: 'attestat_number' },
        { text: 'Группа', value: 'group.name' },
        { text: 'Программа', value: 'group.program.name', groupable: true },
        { text: 'Дата', value: 'date' }
        // { text: 'Тип', value: 'group.program.level' }
      ],
      isLoadingSave: false,
      currentPage: 1,
      requestsPerPageList: [20, 30, 40, 50, 100, 150, 200],
      requestsPerPage: 20,
      requests: [],
      totalRequests: 0,
      loading: false,
      date_end: '',
      menu: false,
      date_start: '',
      menu2: false,
      search: '',
      groups: [],
      programs: [],
      group: '',
      program: '',
      pageCount: 0,
      lenResults: 0
    }
  },
  computed: {
    ...mapState('app', [])
  },
  mounted() {
    this.getDataFromApi()
    this.GET_GROUPS_AND_PROGRAMS().then(
      (response) => {
        this.groups = response.groups
        this.programs = response.programs
      }
    )

  },
  methods: {
    ...mapActions('app', ['GET_CONSULTANTS_FROM_ARCHIVE', 'GET_GROUPS_AND_PROGRAMS']),
    pageUpdateFunction(newPageNumber) {
      this.currentPage = newPageNumber
      this.getDataFromApi()
    },
    itemsPerPageUpdate(value) {
      this.requestsPerPage = value
      this.getDataFromApi()
    },
    destroyFilters() {
      this.date_end = ''
      this.date_start=''
      this.search = ''
      this.group = ''
      this.program = ''
      this.currentPage = 1
      this.getDataFromApi()
    },
    getDataFromApi() {
      this.loading = true

      this.GET_CONSULTANTS_FROM_ARCHIVE({
        page_size: this.requestsPerPage,
        page: this.currentPage,
        date_end: this.date_end,
        date_start: this.date_start,
        search: this.search,
        group: this.group,
        program: this.program
      }).then(
        (response) => {
          this.totalRequests = response.count
          this.requests = response.results
          this.loading = false
          this.currentPage = response.page
          this.lenResults = response.results.length
        }).catch(
        (error) => console.log(error)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
