<template>
  <div v-if="profile && profile.proposal">
    У вас есть заявление на
    <span v-if="profile.proposal.form.type==='high'">повышение квалификации</span>
    <span v-else>обучение профессии "Налоговый консультант"</span>
    <br>
    Группа:<span v-if="!group"> вас еще не добавили в группу обучения</span>
    <div v-if="group">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td>Статус:</td><td>{{ group.status }}</td>
            </tr>
            <tr>
              <td>Дата обучения:</td><td>{{ group.date_start }} - {{ group.date_end }}</td>
            </tr>
            <tr>
              <td>Форма обучения:</td><td>{{ group.form }}</td>
            </tr>
            <tr>
              <td>Программа:</td><td>{{ group.program }}</td>
            </tr>
            <tr>
              <td>Организация:</td><td>{{ group.org }}</td>
            </tr>
            <tr>
              <td>Цена:</td><td>{{ group.value }} руб.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      group: null
    }
  },
  computed: {
    ...mapState('app', ['profile'])
  },
  mounted() {
    if (this.profile.proposal.form.edu_group) {
      this.GET_GROUP_BY_ID(this.profile.proposal.form.edu_group).then(
        (response) => {
          this.group = response
        }
      )
    }
  },
  methods: {
    ...mapActions('app', ['GET_GROUP_BY_ID'])
  }
}
</script>
