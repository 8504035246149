<template>
  <div class="page mt-5">
    <v-row>
      <v-card
        color="lighten-1"
        class="mb-4"
        elevation="0"
      >
        Пожалуйста загрузите следующие документы:
        <v-list-item five-line>
          <v-list-item-content>
            <v-card
              v-for="doc in user_files"
              :key="doc.key"
              class="pa-2 ma-1"
              outlined
            >
              <template>
                <v-icon
                  v-if="doc.is_uploaded"
                  color="green darken-2"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
                {{ doc.type }}
                <span v-if="doc.key==='zayavlenie'">
                  <span v-if="profile.proposal.form.type==='learning'"> на обучение</span>
                  <span v-else> на повышение квалификации</span>
                </span>
                <v-chip
                  v-if="doc.url"
                  small
                  label
                  @click="download_zayavlenie()"
                >
                  <v-icon small>mdi-download</v-icon>
                  Скачать
                </v-chip>
              </template>

              <div class="mt-2">
                <v-file-input
                  v-if="!doc.is_uploaded"
                  ref="zayvfile"
                  v-model="files"
                  label="Выберите файл"
                  outlined
                  class="mt-1"
                  dense
                  @change="upload(doc.key)"
                ></v-file-input>
                <template v-if="doc.template">
                  <v-chip v-if="!proposalNotLast" class="ml-2" small label>
                    <a :href="link_file(doc.template)" download="" target="_blank">
                      скачать шаблон, который нужно подписать</a>
                  </v-chip>
                  <v-chip v-if="proposalNotLast" class="ml-2 error" small label>
                    нет действующего заявления
                  </v-chip>
                  <p v-if="doc.key==='zayavlenie' && !proposalNotLast" class="small">
                    <i>* если вы допустили ошибку в заявлении,
                      <router-link
                        :to="profile.proposal.form.type==='learning' ? {name: 'request_first'} : {name: 'request_second'}"
                        class="color_link"
                      >
                        создайте новое
                      </router-link>
                    </i>
                  </p>
                </template>
              </div>
              <small v-for="f in user_files[doc.key]" class="ml-4">
                <v-icon x-small>mdi-arrow-right</v-icon>
                {{ f.name }} <br>
              </small>
              <!-- <v-btn outlined class='mt-4' @click='finish_upload(doc.key)'> завершить загрузку</v-btn> -->
            </v-card>

          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-row>
    <v-row>
      <v-simple-table style="width:100%;">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
              </th>
              <th class="text-left">
                Название
              </th>
              <th class="text-left">
                Ссылка
              </th>
              <th class="text-left">
                Дата добавления
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="file in user_files"
              v-if="file.is_uploaded"
            >
              <td>
                <v-icon class='mк-1'>
                  mdi-folder
                </v-icon>
              </td>
              <td>{{ file.type }}</td>
              <td>
                <v-btn
                  link
                  small
                  outlined
                  :href='link_file(file.url)'
                >
                  Скачать
                </v-btn>
              </td>
              <td>{{ file.added_date }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>

    <div class="caption mt-5 text-left">
      <!-- <a href="https://github.com/firelayer/board-template" target="_blank" class="font-weight-black">Open on GitHub!</a> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UploadService from "../../services/UploadFilesService"
import router from "@/router"
import { base_url } from '@/services/base_url'

export default {
  data() {
    return {
      unsubscribe: null,
      files: [],
      docs: [
        {
          name: 'Копия паспорта (стр. 2,3,5)',
          field_upload: 'Поле для загрузки',
          key: 'passport',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Копия документа об образовании (диплом ВУЗа о высшем образовании) без приложения',
          field_upload: 'Поле для загрузки',
          key: 'diploma',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Копия СНИЛС',
          field_upload: 'Поле для загрузки',
          key: 'snils',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Заявление в Союз «ПНК»',
          field_upload: 'Поле для загрузки',
          key: 'zayavlenie',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Согласие на обработку персональных данных в Союз «ПНК»',
          field_upload: 'Поле для загрузки',
          key: 'persdata',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Договор на обучение',
          field_upload: 'Поле для загрузки',
          key: 'edudogovor',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Акт к договору обучения',
          field_upload: 'Поле для загрузки',
          key: 'aktfordogovor',
          download_link: null,
          from_template: false,
        },
        {
          name: 'Справка с места работы (в случае оплаты обучения организацией)',
          field_upload: 'Поле для загрузки',
          key: 'spravkafromwork',
          download_link: null,
          from_template: false,
        },
      ],
      folders: [
        {
          subtitle: '12 мая',
          title: 'Подготовка к экзамену',
        },
        {
          subtitle: '15 мая',
          title: 'Публикации',
        },
        {
          subtitle: '1 апреля',
          title: 'Пособия',
        },
      ],
      proposalNotLast: false
    }
  },
  computed: {
    ...mapState('app', ['user', 'profile', 'user_files']),
    color_success() {
      var color = {
        persdata: 'lighten-4',
        zayvfile: 'lighten-4',
        diplomfile: 'lighten-4',
        trudovaya: 'lighten-4'
      }
      if (this.user_files.persdata) {
        color.persdata = 'green lighten-5'
      }
      if (this.user_files.zayvfile) {
        color.zayvfile = 'green lighten-5'
      }
      if (this.user_files.diplomfile) {
        color.diplomfile = 'green lighten-5'
      }
      if (this.user_files.trudovaya) {
        color.trudovaya = 'green lighten-5'
      }

      return color
    }
  },
  beforeMount() {
    this.GET_INFO_PROFILE()
      .then((response) => {
        if (!this.profile.user.is_personal_filled) {
          // если не заполенены личные данные -> направляем на заполнение
          router.push({ name: 'personal' })
        } else {
          this.GET_PROPOSALS_FROM_USER().then(
            (response) => {
              if (response === 0) {
                // если нет никакой заявки -> направляем на заявку
                if (this.profile.user.attestat_number) {
                  const cs = this.profile.user.status.slug

                  if (cs !== 'consultant' && cs !== 'need_docs') {
                    router.push({ name: 'request_second' })
                  } else {
                    this.proposalNotLast = true
                  }
                } else {
                  router.push({ name: 'request_first' })
                }
              }
            }
          )
          this.USER_FILES(this.profile.user.id).then((res) => {
          })
        }
      })
  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess', 'GET_ZAYAVLEMIYE', 'GET_INFO_PROFILE',
      'USER_FILES', 'GET_SCHET_POVYSHENIE', 'FINISH_UPLOAD', 'GET_PROPOSALS_FROM_USER']),
    download_zayav() {
      window.open("https://atknin.com/media/vfpg_1.odt", "_blank")
    },
    upload(type) {
      this.progress = 0
      UploadService.upload(this.files, this.profile.user.id, type, event => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
        .then(response => {
          this.USER_FILES(this.profile.user.id)
          this.files = null
        })
        .catch(() => {
          this.progress = 0
          this.files = null
        })

    },
    download(url) {
      window.open(url, "_blank")
    },
    download_zayavlenie() {
      this.GET_ZAYAVLEMIYE()
    },
    finish_upload(key) {
      this.FINISH_UPLOAD({type: key, id: this.profile.user.id})
        .then(response => {
          this.USER_FILES(this.profile.user.id)
        })
    },
    download_schet_povyshenie() {
      this.GET_SCHET_POVYSHENIE({type: this.pay_update_qualification, id: this.profile.user.id})
    },
    path(val) {
      location.href = val
    },
    link_file(url) {
      // удаляем / в начале строки
      url = url.slice(1)

      return `${base_url()}${url}`
    },
  }
}
</script>

<style lang="scss" scoped>
.color_link {
  color: #0083dd;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
