<template>
  <div class="page mt-5">
  <v-row>
  <v-col>
    <v-btn @click="locationUser('/groupconsultants')">
        <v-icon left>mdi-arrow-left</v-icon>
        Назад
    </v-btn>
  </v-col>
  </v-row>
  <v-row>
 <v-col cols="12" md="4">
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="fio"
                  :label="nameLabel"
                  readonly>
                </v-text-field>
              </v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="phone"
                  :label="phoneLabel"
                  readonly
                  prepend-icon="mdi-phone"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  readonly
                  prepend-icon="mdi-mail"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="inn"
                  :label="innLabel"
                  readonly
                  v-mask="'############'"
                  
                ></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="snils"
                  :label="snilsLabel"
                  readonly
                  v-mask="'###-###-### ##'"
                ></v-text-field>
              </v-skeleton-loader>
            </v-col>
          </v-row>
  </div>
</template>

<script>
import user from '../../assets/user.png';
import { mapState, mapActions, mapGetters,mapMutations } from 'vuex';
import {base_url} from '../../services/base_url.js'; 
import VAvatarUploader from 'vuetify-avatar-uploader';
export default {
  mounted() {
    this.consultant_id = this.$route.query.id
    this.GET_REGIONS().then(response => {
      console.log(response)
      this.regions = response.items
      
      })
    console.log("Loading")
    this.load_profile()

  },
  name: 'ConsultantInfo',
  data: () => ({
    currentRang: null,
    title: 'This will be the title',
    boilerplate: true,
    loading: false,
    name: 'Иван',
    fio:'',
    middle_name: 'Иванович',
    address:'Москва',
    regions: [],
    loginChecked: true,
    last_name: 'Иванов',
    roles: [],
    avatar_img: user,
    email: 'ivan@atknin.ru',
    phone: '+79151322437',
    selectedRegion: {id: null, name: null},
    changeLanguage: '',
    model: null,
    academic_rank: null,
    currentDegree: null,
    currentOrganization: null,
    organization: '',
    edu_type: '',
    date: new Date().toISOString().substr(0, 10),
    menu2: false,
    head_info: '',
    selectedEducation:'',
    inn:'',
    response_inn:'',
    edit: false,
    rang: null,
    organizations: null,
    fullRangArray: null,
    fullDegreeArray: null,
    fullOrganizationArray: [],
    loaded: false,
    snils: '',
    consultant_id: null
  }),
  components: {
  },
  watch:{
    
  },
  computed: {
    ...mapState('app', ['user','profile']),
    ...mapGetters([
    ]),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    surNameLabel(){
      return 'Фамилия'
    },
    scienceOrganizationLabel(){
      return ''
    },
    nameLabel(){
      return 'ФИО'
    },
    patronymicLabel(){
      return 'Отчество'
    },
    hint(){
      return 's'
    },
    birthDayLabel(){
      return ''
    },
    phoneLabel(){
      return 'Телефон'
    },
    addressLabel(){
      return 'Адрес'
    },
    regionLabel(){
      return 'Регион'
    },
    scienceRankLabel(){
      return ''
    },
    scienceDegreeLabel(){
      return ''
    },
    organizationLabel(){
      return 'Организация'
    },
    eduTypeLabel(){
      return 'Тип образования'
    },
    innLabel(){
      return 'ИНН'
    },
    snilsLabel(){
      return 'СНИЛС'
    },
  },
  created() {
    this.loading = true;
  },
  methods: {
    ...mapActions('app', [
      'LOAD_PHOTO',
      'UPDATE_INDIVIDUAL',
      'LANGUAGE_SWITCH',
      'GET_ACADEMIC_DEGREE',
      'GET_ACADEMIC_RANK',
      'ORGANIZATION_HEADERS',
      'GET_PERSONAL_DATA',
      'logged',
      'GET_CONSULTANT_PROFILE_INFO',
      'GET_REGIONS'
    ]),
    ...mapMutations(['SET_APP_LANGUAGE', 'UPDATE_NAME_USER']),
    locationUser(url) {
      window.location.href = url
    },
    load_profile(){
        this.GET_CONSULTANT_PROFILE_INFO(this.consultant_id)
        .then(response => {
            console.log(response.fio)
          this.fio = response.fio
          //this.address = this.response.address
          this.email = response.email
          this.phone = response.phone
          //this.selectedRegion = this.response.region
          //this.selectedEducation = this.response.education
          //this.organization = this.response.organization
          this.inn = response.inn
          /*
          if (this.response.avatar_url !== ''){
            this.avatar_img = base_url() + this.response.data.avatar_url
          }
          */
          this.snils = response.snils
          this.loading = false
          console.log(this.loading)
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}
.primary-color{
  color: #333;
}
.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
