<template>
  <v-app>
    <v-card class="mx-auto content-personal" width="100%" style="padding:30px; margin:30px;" :flat="true">
      <h3>Ваш статус: {{ status.name }}</h3>
      <div class="content-personal__header d-flex justify-space-between align-start">
        <div class="switch d-flex align-start">
          <h1 class="switch__current-lang">
            <v-chip
              class="mr-2 ml-2"
              dark
              outlined
              label
              style="max-width:168px; margin-bottom:20px;"
              color="primary"
              @click="locationUser('/consultant')"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              назад
            </v-chip>
          </h1>
        </div>
      </div>

      <v-card>
        <v-toolbar
          flat
          color="primary"
          dark
        >
          <v-toolbar-title>Тип оплаты</v-toolbar-title>
        </v-toolbar>
        <v-tabs vertical>
          <v-tab v-if="summ_chlen">
            Оплата членского взноса
          </v-tab>
          <v-tab v-if="summ_learning">
            Оплата обучения
          </v-tab>
          <v-tab v-if="summ_high">
            Оплата повышения квалификации
          </v-tab>

          <v-tab-item v-if="summ_chlen">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="summ_chlen"
                      label="Сумма к оплате"
                      :rules="rules"
                      hide-details="auto"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-col>
                  <v-btn
                    color=""
                    outlined
                    class="mr-4"
                    :loading="loaded"
                    :disabled="!allowedPays.chlen"
                    @click="pay(allowedPays ? 'first_chlen' : 'chlen', summ_chlen)"
                  >Оплатить
                  </v-btn>
                </v-col>
                <v-col v-if="!pathTicket">
                  <a @click="makeTicketPay(summ_chlen, 'chlen')">квитанция на оплату</a>
                </v-col>
                <v-col v-else>
                  <a :href="pathTicket" download target="_blank">скачать квитанцию на оплату</a>
                </v-col>
                <v-col>
                  <a
                    @click="openPaymentInvoiceForOrg(summ_chlen, 'chlen')"
                  >счет на оплату</a>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="summ_learning">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="summ_learning"
                      label="Сумма к оплате"
                      :rules="rules"
                      hide-details="auto"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-col>
                  <v-btn
                    color=""
                    outlined
                    class="mr-4"
                    :loading="loaded"
                    :disabled="!allowedPays.learning"
                    @click="pay('payment_learning', summ_learning)"
                  >Оплатить
                  </v-btn>
                </v-col>
                <v-col v-if="!pathTicket">
                  <a @click="makeTicketPay(summ_learning, 'start')">сформировать квитанцию на оплату</a>
                </v-col>
                <v-col v-else>
                  <a :href="pathTicket" download target="_blank">скачать квитанцию на оплату</a>
                </v-col>
                <v-col>
                  <a
                    @click="openPaymentInvoiceForOrg(summ_learning, 'payment_learning')"
                  >счет на оплату</a>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="summ_high">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="summ_high"
                      label="Сумма к оплате"
                      :rules="rules"
                      hide-details="auto"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-col>
                  <v-btn
                    color=""
                    outlined
                    class="mr-4"
                    :loading="loaded"
                    :disabled="!allowedPays.high"
                    @click="pay('payment_high', summ_high)"
                  >Оплатить
                  </v-btn>
                </v-col>
                <v-col v-if="!pathTicket">
                  <a @click="makeTicketPay(summ_high, 'high')">сформировать квитанцию на оплату</a>
                </v-col>
                <v-col v-else>
                  <a :href="pathTicket" download target="_blank">скачать квитанцию на оплату</a>
                </v-col>
                <v-col>
                  <a
                    @click="openPaymentInvoiceForOrg(summ_high, 'payment_high')"
                  >счет на оплату</a>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
      <v-dialog
        v-model="openPaymentInvoice"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Заявка на получение счета на оплату
          </v-card-title>
          <v-card-text v-if="!errorOpenPaymentInvoice">
            <v-textarea
              v-model="dataPaymentInvoice.text"
              solo
              label="Полные реквизиты организации"
            >
            </v-textarea>
            сумма: {{ dataPaymentInvoice.amount }} руб.
            <br><br>
            <v-alert
              border="right"
              color="blue-grey"
              dark
            >
              <ul>
                <li> После нажатия кнопки "отправить" указанные вами данные уйдут на проверку;</li>
                <li> Далее в течение 2-x рабочих дней на вашу электронную почту поступит письмо с прикрепленным счетом для оплаты;</li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-card-text v-else>
            <v-alert
              border="right"
              color="error"
            >
              Данный сервис сейчас не работает. Приносим свои извинения.
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="openPaymentInvoice = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="success"
              text
              @click="makePaymentInvoiceForOrg()"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-row>
      <v-col>
        <h3>История платежей</h3>
        <v-data-table
          :headers="headers"
          :items="payments"
          :loading="history_loading"
          loading-text="Загрузка... Пожалуйста подождите"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { base_url } from '@/services/base_url.js'

export default {
  name: 'Payments',
  data: () => ({
    history_loading: false,
    date: new Date().toISOString().substr(0, 10),
    rules: [
      (value) => !!value || 'Обязательное поле.'
    ],
    loaded: false,
    summ: 0,
    summ_chlen: 0,
    summ_learning: 0,
    summ_high: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    summ_chlen_start: 0,
    summ_chlen_add: 0,
    status: '',
    order_id: null,//this.$route.query.order_id || null,
    headers: [
      {
        text: 'Сумма платежа, р.',
        align: 'start',
        value: 'summ'
      },
      { text: 'Назначение платежа', value: 'comment' },
      { text: 'Статус', value: 'status' },
      { text: 'Дата платежа', value: 'date' }
    ],
    payments: [],
    paymentsPerPage: 10,
    allowedPays: {
      chlen: true,
      learning: false,
      high: false
    },
    checkbox_start: false,
    pathTicket: null,
    dataPaymentInvoice: {
      amount: 0,
      text: '',
      type_of_pay: ''
    },
    openPaymentInvoice: false,
    errorOpenPaymentInvoice: false
  }),
  computed: {
    ...mapState('app', ['user', 'profile'])
  },
  beforeMount() {
    if (this.$route) {
      if (this.$route.query.orderId) {
        this.CHECK_ORDER(this.$route.query.orderId).then(
          () => this.getDataFromApi()
        ).catch(
          (error) => console.log(error.response)
        )
      }
    }
    this.GET_INFO_PROFILE().then(
      (response) => {
        if (this.profile.user.avatar_url !== '') {
          this.avatar_img = base_url() + this.profile.user.avatar_url
        }
        this.status = this.profile.user.status || ''
        let { proposal } = this.profile

        if (proposal) {
          proposal = proposal.form

          if (proposal.type === 'high') {
            this.allowedPays.high = true
            this.summ_high = proposal.price
          } else if (proposal.type === 'learning') {
            this.allowedPays.learning = true
            this.summ_learning = proposal.price
          }

          this.GET_PRICE_FOR_CHLEN().then(
            (response) => {
              this.summ_chlen = response.add
              this.summ_chlen_start = response.start
              this.summ_chlen_add = response.add
            }
          )

        }
      })
    this.getDataFromApi()
  },
  methods: {
    ...mapActions('app', [
      'GET_INFO_PROFILE',
      'GET_PAYMENTS_HISTORY',
      'REGISTER_ORDER',
      'CHECK_ORDER',
      'GET_PRICE_FOR_CHLEN',
      'MAKE_TICKET_PAY',
      'MAKE_PAYMENT_INVOICE_FOR_ORG'
    ]),
    ...mapMutations(['SET_APP_LANGUAGE', 'UPDATE_NAME_USER']),
    locationUser(url) {
      window.location.href = url
    },
    getDataFromApi() {
      this.history_loading = true

      this.GET_PAYMENTS_HISTORY().then(
        (response) => {
          this.totalPayments = response.total
          this.payments = response.items
          this.history_loading = false
        }).catch(
        (error) => console.log(error)
      )
    },
    pay(type, summ) {
      this.REGISTER_ORDER({ amount: summ, type: type }).then(
        (response) => {
          window.location.href = response.formUrl
        }).catch((error) => {console.log(error)})
    },
    /**
     * Когда ставится галочка "добавить первый взнос",
     * пересчитывается сумма членского взноса
     */
    onchangeSummChlen() {
      this.summ_chlen = this.checkbox_start ? this.summ_chlen_start : this.summ_chlen_add
    },
    makeTicketPay(amount = 0, way = 'chlen') {
      if (amount) {
        this.MAKE_TICKET_PAY({ amount: amount,  way: way }).then(
          (response) => this.pathTicket = this.linkFile(response.message)
        )
      }
    },
    linkFile(url) {
      return `${base_url()}${url}`
    },
    openPaymentInvoiceForOrg(amount, type_of_pay) {
      this.dataPaymentInvoice.amount = amount
      this.dataPaymentInvoice.type_of_pay = type_of_pay
      this.openPaymentInvoice = true
    },
    makePaymentInvoiceForOrg() {
      this.MAKE_PAYMENT_INVOICE_FOR_ORG(this.dataPaymentInvoice).then(
        (response) => this.openPaymentInvoice = false
      ).catch(
        (error) => this.errorOpenPaymentInvoice = true
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.description-upload-photo {
  display: none;
  position: absolute;
  //background: black;
  //color: white;
  opacity: 0.65;
  top: 45px;
}

.avatar:hover .description-upload-photo {
  display: block;
}

.upload {
  opacity: 0;
}

.description-photo {
  top: -20px;
}

input[type=file] {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.content-personal {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(124, 77, 255, .05);
  box-shadow: 1px 5px 24px 0 rgba(124, 77, 255, .05);
  border-width: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  background-color: #fff;
  border-radius: 13px !important;
}

.theme--light.v-application {
  color: rgba(0, 0, 0, 0.87);
}

@media screen and (max-width: 767px) {
  .content-personal {
    max-width: 90% !important;
  }
  .content-personal__header {
    flex-direction: column;
  }
}
</style>
