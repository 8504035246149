<template>
  <v-row>
    <v-col v-if="profile" cols="9">
      <h4>Статус: {{ profile.user.status.name }}</h4>
      <v-alert prominent outlined>
        <v-row align="center">
          <v-col class="grow">
            Ваша образовательная группа: {{ profile.user.group.number }}. <br />
            Программа обучения: {{ profile.user.group.program }}. <br />
            Срок обучения: {{ profile.user.group.date_start }} - {{ profile.user.group.date_end }}. <br />
            Дата экзамена: {{ profile.user.group.exam_date }}
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AttestatComponent from "@/components/common/AttestatComponent";

export default {
  components: { AttestatComponent },
  props: {
    status: { required: true, type: Number },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState("app", ["profile"]),
  },
  created() {},
  methods: {
    ...mapActions("app", [])
  },
};
</script>
