<template>
  <v-card
    class="mx-aut mb-8 mt-10"
    dark
    style="background-color: rgb(4, 162, 124)"
  >
    <v-card-title>
      <a href='https://pnk.palata-nk.ru/'><img style="height: 60px" src="../../assets/logo5.svg" alt="..."/></a>
      <div v-if="profile.user.attestat_number && !chlenBool" class="small">
        <router-link :to="{name: 'payments'}" class="red btn-link white--text">Оплатить членский взнос</router-link>
      </div>
      <div v-else class="title font-weight-light">
        Членство в палате <br>
        <span class="small">до {{ new Date(profile.user.chlen_date).toLocaleString("ru", {month: "long", day: "numeric", year: "numeric"}) }}</span>
      </div>
    </v-card-title>

    <v-card-text v-if="profile.user.attestat_number" class="">
      Номер аттестата:
      <v-chip label light color="white" x-small>
        <b> {{ profile.user.attestat_number }} </b></v-chip>
      <br/>
      Аттестат действителен до: <i>{{ profile.user.date_end }}</i>

    </v-card-text>

    <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>

import { mapState } from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState('app', ['profile']),
    /**
     * Член или не член, исходя из даты окончания членства
     * @returns {boolean}
     */
    chlenBool() {
      return new Date(this.profile.user.chlen_date) >= new Date()
    }
  }
}
</script>
