<template>
    <div>
        <h3 class='font-weight-black ml-1 b'>ЗАЯВЛЕНИЕ НА АККРЕДИТАЦИЮ  </h3>
        <p class="font-weight-black ml-1 b">
            также при продлении сроков аккредитации<br>
        </p>
        <template v-if='sent'>
            <v-card
                color="grey lighten-3"
                class="mb-12 text-center"
                height="200px"
            >
                <v-icon class='mt-5' color='success ' x-large>mdi-check-circle </v-icon>
                <p class='ma-2'>
                    Ваше заявление принято, на указанный e-mail отправлена инструция для входа. 
                    <br>Запомните данные - они будут использоваться для входа в Инфо-ресурс
                </p>
            </v-card>
              <v-btn   @click="path('/login/')">
                        Войти в личный кабинет
                    </v-btn>
        </template>
        <template v-else>
            <v-row  class="pa-5">
                
                <v-col cols="4" class='pa-0'>
                    <v-subheader>ИНН Организации</v-subheader>
                </v-col>
                <v-col cols="8"  class='pa-0'>
                    <v-text-field
                    solo-inverted
                    v-model='form.inn'
                    ></v-text-field>

                    <v-btn @click="get_inn()" :disabled='form.inn<100000 || !form.inn'>предзаполнить</v-btn>
                </v-col>
            </v-row>
            <v-row  class="pa-5">
                <template v-for='field in fields'>
                    
                <v-col cols="4" class='pa-0'>
                    <v-subheader>{{field.name}}</v-subheader>
                </v-col>
                <v-col cols="8"  class='pa-0'>
                    <v-text-field
                    :label="field.label"
                    solo-inverted
                    v-model='form[field.key]'
                    :prefix="field.prefix"
                    ></v-text-field>
                </v-col>
                </template>
                
               
            </v-row>
            <v-checkbox
                class="ml-4"
                v-for="pr in programs"
                v-model="pr.value"
                :label="pr.name"
                :v-bind="pr.name"
            ></v-checkbox>

            <v-btn
                color="primary"
                @click="sent = true"
                class='ml-4'
            >
                Подать заявление
            </v-btn>
        </template>
        
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
  export default {
    data () {
      return {
        e6: 1,
        type1:false,
        questions:{
            'previous': false,
        },
        region:'',
        sent:false,
        citt:'',
        pers_data:true,
        regions:['Московская область', 'Красноярский край', 'Республика Хакасия'],
        cities: ['Москва', 'Красноярск', 'Абакан'],
        type2:true,
        form: {
            name:null,
         
        },
        programs:[
            {value:false,name:'Налоговое консультирование 180'},
            {value:false,name:'Налоговое консультирование 260'},
            {value:false,name:'Налоговое консультирование 380'},
        ],
        fields:[
            {key: 'name', name:'Полное наименование организации',label:'',prefix:''},
            {key: 'manager', name:'ФИО заявителя с указанием должности',label:'',prefix:''},
            // {key: 'managerinfo', name:'Должность руководителя',label:'',prefix:''},
            {key: 'contactperson', name:'Лицо,  уполномоченное взаимодействовать с Союзом “ПНК” (с указанием должности)',label:'',prefix:''},
            {key: 'licence', name:'Сведения о  лицензии (номер, когда и кем выдана',label:'',prefix:''},
            {key: 'email', name:' e-mail',label:'',prefix:''},
            {key: 'website', name:'Адрес сайта ',label:'',prefix:''},
            {key: 'phone', name:'Телефон',label:'',prefix:''},
            {key: 'uladdress', name:'Юридический адрес ',label:'',prefix:''},
            {key: 'postaddress', name:'Почтовый адрес',label:'',prefix:''},
            {key: 'kpp', name:'КПП',label:'',prefix:''},
            {key: 'rekvisite', name:'Банковские реквизиты',label:'',prefix:''},
        ],
        orgs:[
            'Байкальский государственный университет (ФГБОУ ВО)',
            'Дистанционное обучение',
        ]
      }
      
    },
    // INN_INFO
    methods:{
        ...mapActions('app', ['INN_INFO', 'GET_PROGRAMS']),
        step_value(){
            if (this.questions.previous) return 2
            else return 1
        },
        path(val){
            location.href = val
        },
        get_inn(){
             // 9715295750
            
            this.INN_INFO({inn:this.form.inn})
            
            .then(res => {
                this.form['name'] = res.suggestions[0].value;
                this.form['kpp'] = res.suggestions[0].data.kpp;
                this.form['manager'] = res.suggestions[0].data.management.name;
                this.form['managerinfo'] = '';
                this.form['uladdress'] = res.suggestions[0].data.address.value;
                

            })
        }
    }
  }
</script>

<style lang="scss" scoped>
.b{
    color: #333 !important;
}
</style>
