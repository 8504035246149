import http from '../http-common'
import { base_url } from './base_url.js'

class UploadFilesService {
  upload(file,uid, type, onUploadProgress) {
    const formData = new FormData()

    const base = base_url()

    formData.append('file', file)
    formData.append('uid', uid)
    formData.append('type', type)

    return http.post(base + 'api/consultants/upload-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  upload_org(file,uid, type, onUploadProgress) {
    const formData = new FormData()

    const base = base_url()

    formData.append('file', file)
    formData.append('uid', uid)
    formData.append('type', type)

    return http.post(base + 'api/partners/upload-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    })
  }

  async upload_program_file(file, group_id, onUploadProgress) {
    const formData = new FormData()
    const base = base_url()

    formData.append('file', file)
    formData.append('group_id', group_id)

    const res = await http.post(base + 'api/partners/upload-program-file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `token ${localStorage.token}`
      },
      onUploadProgress
    })

    return res.data
  }

  getFiles(uid) {
    return http.get(base + 'api/consultants/get-user-files?id=' + uid)
  }
}

export default new UploadFilesService()
