<template>
    <v-row>
        <v-col>
            <h3>Список учеников</h3>
            <v-data-table
                :headers="headers"
                :items="consultants"
                :page="currentPage"
                :server-items-length="totalConsultants"
                :items-per-page="consultantsPerPage"
                :loading="loading"
                @update:page="pageUpdateFunction"
                @update:items-per-page="itemsPerPageUpdate"
                loading-text="Загрузка... Пожалуйста подождите"
                class="elevation-1"
                @click:row="handleClick"
            ></v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { realtime, TIMESTAMP } from '../../firebase'
import uniqueid from '../../utils/uniqueid'

export default {
  data() {
    return {
      headers: [
          {
            text: 'ФИО',
            align: 'start',
            sortable: false,
            value: 'fio',
          },
          {text:'Номер аттестата', value: 'attestat_number'},
          {text:'ИНН', value: 'inn'},
          {text:'СНИЛС', value:'snils'},
          {text:'Телефон', value:'phone'},
          {text: 'E-mail', value:'email'},
          {text: 'Группа', value:'group'}
        ],
        desserts: [
          {
            name: 'Группа 3',
            calories: 'активная',
            fat: 'зачислить',
            carbs: 24,
            protein: 4.0,
            iron: '1%',
          },
          
        ],
      unsubscribe: null,
      cards: [],
      lists: [{
        id: 0,
        label: 'Templates (Apps Presets)',
        description: 'Web apps fully integrated with Firebase (blog, chat, chrome extension, etc)'
      }, ],

      isLoadingSave: false,

      // add variables
      addDialog: false,
      addToList: {},
      newCardText: '',
      newCardName: '',
      user:{
        fio: 'Иванов Иван Иванович',
      },
      // delete variables
      deleteDialog: false,
      cardToDelete: null,
      isLoadingDelete: false,
      consultants: [],
      selectedConsultant: '',
      current_group_id: this.$route.query.id,
      group_number: '',
      currentPage: 1,
      totalConsultants:0,
      consultantsPerPage: 10,
      dialog: false,
      level: '',
      all_edu_programs: [],
      edu_programs: [],
      statuses: [],
      status: '',
      program: ''
    }
  },
  computed: {
    ...mapState('app', ['user'])
  },
  mounted() {
      this.getDataFromApi()
  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess', 'GET_GROUPS_CONSULTANTS']),
    filterObject(item, queryText, itemText) {
      return (
        item.inn.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        item.fio.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    getColor (calories) {
        if (calories > 400) return 'red'
        else if (calories > 200) return 'orange'
        else return 'green'
    },
    path(val){
      location.href = val
    },
    pageUpdateFunction(newPageNumber) {
        this.currentPage = newPageNumber
        this.getDataFromApi()
    },
    itemsPerPageUpdate(value){
        this.consultantsPerPage = value
        this.getDataFromApi()
    },
    getDataFromApi () {
          this.loading = true
          const itemsPerPage = this.consultantsPerPage
          
          this.GET_GROUPS_CONSULTANTS({q:this.consultantsPerPage, page:this.currentPage})
            .then(response => {
                this.totalConsultants = response.total
                this.consultants = response.items
                this.loading = false
          }).catch(error => {
            console.log(error)
        });
    },
    viewDetails(id){
      location.href='/consultant-profile?id=' + id
    },
    handleClick(value) {
        this.viewDetails(value.id);
        console.log(value.id)
    },
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}
.primary-color{
  color: #333;
}
.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
