<template>
  <main class="container mt-4">

    <v-container fluid grid-list-xl>
      <v-chip
        class="mr-2 "
        dark
        outlined
        label
        style="max-width:168px; margin-bottom:20px;"
        href="/consultant/"
        color="primary"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        назад
      </v-chip>
      <v-row>
        <v-col
          v-for="sem in seminars"
          :key="sem.id"
          cols="12"
          sm="12"
          md="12"
        >
          <v-card
            class="elevation-16 mx-auto"
          >
            <v-container class="text-wrap">
              <h5>{{ sem.name }}</h5>
            </v-container>
            <v-card-text>
              <v-chip label small outlined color="success">
                {{ sem.academ_hours }}
              </v-chip>
            </v-card-text>
            <v-card-subtitle class="text-caption">{{ sem.description }}</v-card-subtitle>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                v-if="!sem.already_requested"
                text
                color="primary"
                :loading="sem.awaitingRequest"
                @click="request_sem(sem)"
              >Записаться
              </v-btn>
              <v-btn v-if="sem.already_requested && !sem.is_allowed" text disabled>Вы уже записались</v-btn>
              <v-btn v-if="sem.is_allowed" text color="success" @click="view_sem(sem.id)">Смотреть</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      iframe_url: '',
      items: [],
      seminars: [],
      showSeminars: false,
      awaitingRequest: false
    }
  },
  mounted() {
    this.GET_SEMINARS().then((res) => {
      this.seminars = res.items
    })
  },
  methods: {
    ...mapActions('app', ['GET_SEMINARS', 'REGISTER_TO_SEMINAR']),
    view_sem(id) {
      location.href = '/video-seminar?id=' + id
    },
    request_sem(item) {
      const idx = this.seminars.findIndex((o) => o.id === item.id)

      this.seminars[idx].awaitingRequest = true
      let tmp_sems = this.seminars

      this.seminars = []
      this.seminars = tmp_sems
      console.log(item)
      this.REGISTER_TO_SEMINAR(item.id).then((res) => {
        this.seminars[idx].awaitingRequest = false
        this.seminars[idx].already_requested = true
        tmp_sems = this.seminars
        this.seminars = []
        this.seminars = tmp_sems
      })
      console.log(this.seminars)
    }
  }
}
</script>

<style scoped>
.flex {
  flex-grow: 0;
}

.hero-section {
  align-items: flex-start;
  background-image: linear-gradient(15deg, #0f4667 0%, #2a6973 150%);
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

@media (min-width: 540px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .card-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card-spicker {
  list-style: none;
  position: relative;
}

.card-spicker:before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card__background {
  background-size: cover;
  background-position: center;
  border-radius: var(--spacing-l);
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  trsnsform: scale(1) translateZ(0);
  transition: filter 200ms linear,
  transform 200ms linear;
}

.card-spicker:hover .card__background {
  transform: scale(1.05) translateZ(0);
}

.card-grid:hover > .card:not(:hover) .card__background {
  filter: brightness(0.5) saturate(0) contrast(1.2) blur(30px);
}

.card__content {
  left: 10px;
  padding: var(--spacing-l);
  position: absolute;
  bottom: 0;
  color: white;
}

.card__category {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.card__heading {
  color: var(--text-lighter);
  font-size: 1.9rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}
</style>
<style scoped>
/* Variables */
/* Fonts */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,700);

.bg-red {
  color: #e52d27;
}

/* Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  max-width: 46em;
}

.timeline:before {
  background-color: black;
  content: '';
  position: absolute;
  top: 0;
  left: 2em;
  width: 2px;
  height: 100%;
}

.timeline-event {
  position: relative;
}

.timeline-event:hover .timeline-event-icon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #04A27C;
}

.timeline-event:hover .timeline-event-thumbnail {
  -moz-box-shadow: inset 40em 0 0 0 #04A27C;
  -webkit-box-shadow: inset 40em 0 0 0 #04A27C;
  box-shadow: inset 40em 0 0 0 #04A27C;
}

.timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
}

.timeline-event-copy h3 {
  font-size: 1.75em;
}

.timeline-event-copy h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}

.timeline-event-copy strong {
  font-weight: 700;
}

.timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

.timeline-event-icon {
  -moz-transition: -moz-transform 0.2s ease-in;
  -o-transition: -o-transform 0.2s ease-in;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: black;
  outline: 10px solid white;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: 2em;
  left: -0.9em;
  width: 1em;
  height: 1em;
}

.timeline-event-thumbnail {
  -moz-transition: box-shadow 0.5s ease-in 0.1s;
  -o-transition: box-shadow 0.5s ease-in 0.1s;
  -webkit-transition: box-shadow 0.5s ease-in;
  -webkit-transition-delay: 0.1s;
  transition: box-shadow 0.5s ease-in 0.1s;
  color: white;
  font-size: 0.75em;
  background-color: black;
  -moz-box-shadow: inset 0 0 0 0em #16336E;
  -webkit-box-shadow: inset 0 0 0 0em #16336E;
  box-shadow: inset 0 0 0 0em #16336E;
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}

</style>
<style scoped>
.cherdack {
  -webkit-box-shadow: 0 0 16px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 9px 16px 0 rgb(0 0 0 / 20%);

}

.btn-shadow {
  -webkit-box-shadow: 0 0 16px rgb(51 51 51 / 20%);
  box-shadow: 0 0 16px rgb(51 51 51 / 20%);
}

.icon-mb {
  margin-bottom: 3px;
  margin-right: 3px;
}

.nav-link {
  color: #333 !important;
  cursor: pointer !important;
}

.btn-primary {
  color: #F0EFED !important;
  cursor: pointer !important;
}

.b {
  color: #333 !important;
}

.b-white {
  color: #F0EFED !important;
}

.dark-green-text {
  color: #16336E !important;
}

.bg-image-1 {
  background: #04A27C url('https://pro-women.ru/local/templates/prowomen/assets/img/index/participantBg.png') bottom left no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-2 {
  background: #16336E url('https://pro-women.ru/local/templates/prowomen/assets/img/index/liderBg.png') bottom left no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-3 {
  background: #eff8f3 100% 100% no-repeat !important;
  background-size: cover; /* Масштабируем фон */
  color: white !important;
  font-weight: 500;
  border-radius: 10px;
}

.bg-image-4 {
  background: linear-gradient(#effdf6, #fff) url('https://pro-women.ru/local/templates/prowomen/assets/img/mentoring/mentoring-manual-item-bg.svg') top right no-repeat !important;
}

.bg-image-5 {
  background: #04A27C url('https://pro-women.ru/local/templates/prowomen/assets/img/index/soviet-bg.svg') top right no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-6 {
  background: #04A27C url('https://pro-women.ru/local/templates/prowomen/assets/img/index/subscribeBg.png') bottom right no-repeat !important;
  color: white !important;
  font-weight: 500;
}

.bg-image-0 {
  background: linear-gradient(252.6deg, #04A27C -3.65%, #067D61 104.61%) !important;
}

/*  */

.gg-arrow-long-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 2px;
  width: 50%;
  top: 40%;
  left: 40%;
  right: 10%;
  height: 6px;
}

.gg-arrow-long-right::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 6px;
  height: 6px;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
}

@import "https://fonts.googleapis.com/css?family=Poppins:100,300,400,500,700,900";
@import "https://fonts.googleapis.com/css?family=Caveat";

@import "../landing/landing_css/theme.css";

@import "../landing/landing_css/all.css";
/* @import "./landing_css/aos.css"; */
@import "../landing/landing_css/cookieconsent.min.css";
@import "../landing/landing_css/magnific-popup.css";
@import "../landing/landing_css/odometer-theme-minimal.css";
@import "../landing/landing_css/prism-okaidia.css";
@import "../landing/landing_css/simplebar.css";
@import "../landing/landing_css/smart_wizard.css";
@import "../landing/landing_css/smart_wizard_theme_arrows.css";
@import "../landing/landing_css/smart_wizard_theme_circles.css";
@import "../landing/landing_css/smart_wizard_theme_dots.css";
@import "../landing/landing_css/swiper.css";
@import "../landing/landing_css/theme.css";
@import "../landing/landing_css/rtl.css";
@import "../landing/landing_css/hover_menu.css";
@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
</style>
