<template>
  <main class="container-fluid mt-4 mb-10">
    <v-row style="min-height:800px">
      <v-col cols="4">
        <div style="text-align: center;"><v-progress-circular v-if="loading" indeterminate class="primary--text"/></div>
        <v-navigation-drawer
          permanent
          left
          small
          style="width:100%"
        >
          <v-treeview
            open-all
            :items="items"
            item-key="id"
          >
            <template slot="label" slot-scope="{ item }">
              <small>
                <a style="width:300px" @click="super_loger(item)"> {{ item.name }}</a>
              </small>
            </template>
          </v-treeview>
          <br>
          <a @click="alert_lock = true">
            <span class="lock"><LockSVG></LockSVG><small>Учебно-методические материалы</small></span>
          </a>
          <br><br>
          <a @click="alert_lock = true">
            <span class="lock"><LockSVG></LockSVG><small>Видеосеминары</small></span>
          </a>
          <br><br>
          <v-alert
            v-model="alert_lock"
            color="red"
            transition="scale-transition"
            dismissible
          >
            Доступны только при прохождении обучения по программе!
          </v-alert>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="8">
        <v-progress-circular
          v-if="!loaded"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <div v-if="numPages">
          <pdf
            :src="iframe_url"
            :page="currentPage"
            style="width:85%; height:90%"
          >
          </pdf>
          <v-btn class="ma-3" @click="prev">Предыдущая</v-btn>
          <v-btn class="ma-3" @click="next">Следующая</v-btn>
          Страница: {{ currentPage }} / {{ numPages }}
        </div>
      </v-col>
    </v-row>

  </main>
</template>

<script>
import { mapActions } from 'vuex'
import pdf from 'vue-pdf'
import LockSVG from '@/pages/landing/components/LockSVG'

export default {
  components: {
    pdf,
    LockSVG
  },
  data() {
    return {
      iframe_url: '',
      items: [],
      loaded: false,
      loading: true,
      currentPage: 0,
      pageCount: 0,
      numPages: undefined,
      page: 1,
      alert_lock: false
    }
  },
  methods: {
    ...mapActions('app', ['GET_LIBRARY', 'GET_MATERAIL']),
    super_loger(item) {
      if (item.type !== undefined) {

        this.iframe_url = ''
        this.loaded = false
        this.getMaterial(item.id)

      }
    },
    getMaterial(item_id) {
      this.GET_MATERAIL(item_id).then((res) => {
        this.iframe_url = res
        this.loaded = true
        const l = pdf.createLoadingTask(this.iframe_url)

        l.promise.then((pdf) => {
          this.numPages = pdf.numPages
          this.currentPage = 1
          console.log(pdf.numPages)
        })
      })
    },
    load() {
      this.loaded = false
    },
    next() {
      if (this.currentPage + 1 <= this.numPages) {
        this.currentPage += 1
      } else {
        this.currentPage = 1
      }
    },
    prev() {
      if (this.currentPage - 1 > 0) {
        this.currentPage -= 1
      } else {
        this.currentPage = this.numPages
      }
    }
  },
  mounted() {
    this.GET_LIBRARY(0)
      .then((res) => {
        this.loading = false
        this.items = res.items
        const news = res.items.find(
          (item) => item.id === 6
        ).children
        const last_news = news[news.length - 1]
        this.getMaterial(last_news.id)
      })
  }
}
</script>

<style scoped>
.flex {
  flex-grow: 0;
}

.hero-section {
  align-items: flex-start;
  background-image: linear-gradient(15deg, #0f4667 0%, #2a6973 150%);
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding: var(--spacing-xxl) var(--spacing-l);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

@media (min-width: 540px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .card-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.card-spicker {
  list-style: none;
  position: relative;
}

.card-spicker:before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card__background {
  background-size: cover;
  background-position: center;
  border-radius: var(--spacing-l);
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  trsnsform: scale(1) translateZ(0);
  transition: filter 200ms linear,
  transform 200ms linear;
}

.card-spicker:hover .card__background {
  transform: scale(1.05) translateZ(0);
}

.card-grid:hover > .card:not(:hover) .card__background {
  filter: brightness(0.5) saturate(0) contrast(1.2) blur(30px);
}

.card__content {
  left: 10px;
  padding: var(--spacing-l);
  position: absolute;
  bottom: 0;
  color: white;
}

.card__category {
  color: var(--text-light);
  font-size: 0.9rem;
  margin-bottom: var(--spacing-s);
  text-transform: uppercase;
}

.card__heading {
  color: var(--text-lighter);
  font-size: 1.9rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
  word-spacing: 100vw;
}
</style>
<style scoped>
/* Variables */
/* Fonts */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,700);


.timeline-event:hover .timeline-event-icon {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #04A27C;
}

.timeline-event:hover .timeline-event-thumbnail {
  -moz-box-shadow: inset 40em 0 0 0 #04A27C;
  -webkit-box-shadow: inset 40em 0 0 0 #04A27C;
  box-shadow: inset 40em 0 0 0 #04A27C;
}

.timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
}

.timeline-event-copy h3 {
  font-size: 1.75em;
}

.timeline-event-copy h4 {
  font-size: 1.2em;
  margin-bottom: 1.2em;
}

.timeline-event-copy strong {
  font-weight: 700;
}

.timeline-event-copy p:not(.timeline-event-thumbnail) {
  padding-bottom: 1.2em;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px !important;
  }
}
.lock {
  margin:0px 12px;
}
</style>
