<template>
  <v-row>
    <v-col cols="9">
        <h4>Статус: загрузка документов</h4>
      <v-alert prominent outlined color="#191654">
        <v-row align="center">
          <v-col class="grow">
            Пожалуйста загрузите документы и обновите данные профиля
          </v-col>

          <v-col class="shrink">
            <v-btn class="mb-1" dark small color="#191654" href="/docs/"
              >Загрузить документы</v-btn
            >

            <v-btn dark small color="#191654" href="/personal/"
              >Обновить информацию</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AttestatComponent from "@/components/common/AttestatComponent";

export default {
  components: { AttestatComponent },
  props: {
    status: { required: true, type: Number }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState("app", ["user", "profile", "user_files"]),
    zayav_link() {
      return `/request-second/?attest=${this.profile.user.attestat_number}`;
    },
    show_badge() {
      return true;
    },
  },
  created() {},
  methods: {
    ...mapActions("app", [
      "showError",
      "showToast",
      "showSuccess",
      "GET_ZAYAVLEMIYE",
      "GET_INFO_PROFILE",
      "USER_FILES",
      "GET_SCHET_POVYSHENIE",
      "FINISH_UPLOAD",
    ]),
  },
};
</script>
