<template>
  <v-app>
    <v-card class="mx-auto content-personal" width="100%" style="padding:30px; margin:30px;" :flat="true">
      <div class="content-personal__header d-flex justify-space-between align-start">
        <div class="switch d-flex align-start">
          <h1 class="switch__current-lang">
            <v-chip
              class="mr-2 ml-2"
              dark
              outlined
              label
              style="max-width:168px; margin-bottom:20px;"
              color="primary"
              @click="locationUser('/consultant')"
            >
              <v-icon left>mdi-arrow-left</v-icon>
              назад
            </v-chip>
          </h1>
        </div>
      </div>
      <v-card-text>
        <v-row>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                height="100px"
                width="100px"
                class="avatar"
              >
                <v-img :src="avatar_img"></v-img>
                <div
                  class="description-upload-photo"
                >
                  <v-icon large>
                    mdi-cloud-upload
                  </v-icon>
                  <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    @change="onUploadFile"
                  />
                </div>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-skeleton-loader
                  :loading="loading"
                  height="94"
                  type="list-item-two-line"
                >
                  <v-list-item-title v-if="profile" class="title"> {{ profile.user.last_name }} {{ profile.user.name }}</v-list-item-title>
                  <v-list-item-subtitle v-if="profile">{{ profile.user.email }}</v-list-item-subtitle>
                  <v-list-item-subtitle></v-list-item-subtitle>
                  <template v-for="(item, index) in roles">
                    <v-chip :key="index" class="" x-small>{{ item.name }}</v-chip>
                  </template>
                </v-skeleton-loader>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="name"
                :label="nameLabel"
                required
              >
              </v-text-field>
              <span v-if="$v.name.$invalid" class="red--text">
                Укажите имя
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="last_name"
                :label="surNameLabel"
                required
              ></v-text-field>
              <span v-if="$v.last_name.$invalid" class="red--text">
                Укажите фамилию
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="4">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="middle_name"
                :label="patronymicLabel"
                required
              >
              </v-text-field>
            </v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="phone"
                v-mask="'+7 (###) #######'"
                :label="phoneLabel"
                required
                prepend-icon="mdi-phone"
              ></v-text-field>
              <span v-if="$v.phone.$invalid" class="red--text">
                Укажите номер телефона
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="email"
                label="E-mail"
                required
                prepend-icon="mdi-mail"
              ></v-text-field>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="inn"
                v-mask="'############'"
                :label="innLabel"
                required
                :disabled="response_inn !== '' && response_inn !== null"
              ></v-text-field>
              <span v-if="$v.inn.$invalid" class="red--text">
                Некорректный ИНН
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="snils"
                v-mask="'###-###-### ##'"
                :label="snilsLabel"
                required
              ></v-text-field>
              <span v-if="$v.snils.$invalid" class="red--text">
                Некорректный номер СНИЛС
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="address"
                :label="addressLabel"
                required
              ></v-text-field>
              <span v-if="$v.address.$invalid" class="red--text">
                Уважите свой адрес
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-autocomplete
                v-model="selectedRegion"
                :items="regions"
                item-value="id"
                color="white"
                item-text="name"
                :label="regionLabel"
                return-object
              ></v-autocomplete>
              <span v-if="$v.selectedRegion.$invalid" class="red--text">
                Выберете регион
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="organization"
                :label="organizationLabel"
                required
              ></v-text-field>
              <span v-if="$v.organization.$invalid" class="red--text">
                Укажите место работы
              </span>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-text-field
                v-model="position_in_org"
                label="Должность"
              ></v-text-field>
            </v-skeleton-loader>
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader
              :loading="loading"
              height="94"
              type="list-item-two-line"
            >
              <v-select
                v-model="selectedEducation"
                :items="educations_list"
                :label="eduTypeLabel"
              ></v-select>
              <span v-if="$v.selectedEducation.$invalid" class="red--text">
                Укажите образование
              </span>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color=""
          outlined
          class="mr-4"
          :loading="loaded"
          :disabled="$v.$invalid"
          @click="save_btn"
        >Сохранить</v-btn>
        <span v-if="$v.$invalid" class="red--text">
          Пожалуйста, исправьте ошибки выше
        </span>
      </v-card-actions>
    </v-card>

  </v-app>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email, minLength, numeric, alpha } from 'vuelidate/lib/validators'
import user from '../../assets/user.png'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { base_url } from '@/services/base_url'
import router from '@/router'

const myValidSelectedRegion = (value) => !!value.id

export default {
  name: 'PersonalData',
  mixins: [validationMixin],
  data: () => ({
    currentRang: null,
    title: 'This will be the title',
    boilerplate: true,
    loading: false,
    name: 'Иван',
    middle_name: 'Иванович',
    address:'Москва',
    regions: [],
    loginChecked: true,
    last_name: 'Иванов',
    roles: [],
    avatar_img: user,
    email: 'ivan@atknin.ru',
    phone: '+79151322437',
    selectedRegion: { id: null, name: null },
    changeLanguage: '',
    model: null,
    academic_rank: null,
    currentDegree: null,
    currentOrganization: null,
    organization: '',
    edu_type: '',
    date: new Date().toISOString().substr(0, 10),
    menu2: false,
    head_info: '',
    selectedEducation:'',
    inn:'',
    response_inn:'',
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters'
    },
    edit: false,
    rang: null,
    organizations: null,
    fullRangArray: null,
    fullDegreeArray: null,
    fullOrganizationArray: [],
    loaded: false,
    snils: '',
    chipstatus: true,
    position_in_org: ''
  }),
  validations: {
    name: { required, maxLength: maxLength(30) },
    last_name: { required, maxLength: maxLength(30) },
    email: { required, email },
    phone: { required,},
    snils: { required, maxLength: maxLength(14), minLength: minLength(14) },
    inn: { required, maxLength: maxLength(12), minLength: minLength(12) },
    address: { required },
    selectedRegion: { myValidSelectedRegion },
    selectedEducation: { required },
    organization: { required }
  },
  computed: {
    ...mapState('app', ['user','profile', 'educations_list']),
    ...mapGetters([
    ]),
    checkboxErrors() {
      const errors = []

      if (!this.$v.checkbox.$dirty) return errors

      return errors
    },
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    surNameLabel() {
      return 'Фамилия'
    },
    scienceOrganizationLabel() {
      return ''
    },
    nameLabel() {
      return 'Имя'
    },
    patronymicLabel() {
      return 'Отчество'
    },
    hint() {
      return 's'
    },
    birthDayLabel() {
      return ''
    },
    phoneLabel() {
      return 'Телефон'
    },
    addressLabel() {
      return 'Адрес'
    },
    regionLabel() {
      return 'Регион'
    },
    scienceRankLabel() {
      return ''
    },
    scienceDegreeLabel() {
      return ''
    },
    organizationLabel() {
      return 'Организация'
    },
    eduTypeLabel() {
      return 'Тип образования'
    },
    innLabel() {
      return 'ИНН'
    },
    snilsLabel() {
      return 'СНИЛС'
    }
  },
  mounted() {
    this.GET_REGIONS().then(
      (response) => this.regions = response.items
    )

    this.load_profile()
  },
  created() {
    // this.loading = true;
  },
  methods: {
    ...mapActions('app', [
      'LOAD_PHOTO',
      'UPDATE_INDIVIDUAL',
      'LANGUAGE_SWITCH',
      'GET_ACADEMIC_DEGREE',
      'GET_ACADEMIC_RANK',
      'ORGANIZATION_HEADERS',
      'GET_PERSONAL_DATA',
      'logged',
      'GET_INFO_PROFILE',
      'GET_REGIONS'
    ]),
    ...mapMutations(['SET_APP_LANGUAGE', 'UPDATE_NAME_USER']),
    locationUser(url) {
      window.location.href = url
    },
    async onUploadFile(e) {

      const file = e.target.files || e.dataTransfer.files
      const blob = new Blob([file[0]], { type: ['image/jpg', 'image/jpeg', 'image/png'] })
      const files = new FormData()

      files.append('img', blob)
      files.append('id', this.GETTERS_LOGIN)
      files.append('name', file[0].name)

      await this.LOAD_PHOTO(files)
        .then((res) => {
          this.GET_INFO_PROFILE()
            .then((response) => {
              if (this.profile.user.avatar_url !== '') {
                this.avatar_img = base_url() + this.profile.user.avatar_url
              }
            })
        })

    },
    load_profile() {
      this.GET_INFO_PROFILE()
        .then((response) => {
          this.name = this.profile.user.name
          this.middle_name = this.profile.user.middle_name
          this.address = this.profile.user.address
          this.last_name = this.profile.user.last_name
          this.email = this.profile.user.email
          this.phone = this.profile.user.phone
          this.selectedRegion = this.profile.user.region
          this.selectedEducation = this.profile.user.education
          this.organization = this.profile.user.organization
          this.inn = this.profile.user.inn
          this.response_inn = this.profile.user.inn
          if (this.profile.user.avatar_url !== '') {
            this.avatar_img = base_url() + this.profile.user.avatar_url
          }
          this.snils = this.profile.user.snils
          this.position_in_org = this.profile.user.position_in_org
        })
    },
    get_params() {
      return {
        data: {
          id: this.GETTERS_LOGIN,
          name: this.name,
          last_name: this.last_name,
          middle_name: this.middle_name,
          phone: this.phone,
          address: this.address,
          region: this.selectedRegion.id,
          education: this.selectedEducation,
          organization: this.organization,
          inn: this.inn,
          snils: this.snils,
          position_in_org: this.position_in_org

        }
      }
    },
    async save_btn() {
      this.boilerplate = true
      this.loaded = true

      await this.UPDATE_INDIVIDUAL(this.get_params())
        .then((res) => {
          if (res.status === 200) {
            this.loaded = false
            this.load_profile()
            alert('Данные успешно обновлены')
            if (!this.profile.user.attestat_number) {
              router.push({ name: 'docs' })
            }
          }
        })
        .catch((e) => {
          this.dialog = true
          this.loaded = false
          this.info = `${e}`
          this.$messageHelper(`${e}`, 'error', 3000)
        })

    },
    clear() {
      this.$v.$reset()
      this.name = ''
      this.email = ''
      this.select = null
      this.checkbox = false
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>
<style lang="scss" scoped>
  .description-upload-photo{
    display: none;
    position: absolute;
    //background: black;
    //color: white;
    opacity: 0.65;
    top:45px;
  }
  .avatar:hover .description-upload-photo{
    display: block;
  }
  .upload{
    opacity: 0;
  }
  .description-photo{
    top:-20px;
  }
  input[type=file] {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .content-personal{
    -webkit-box-shadow: 1px 5px 24px 0 rgba(124,77,255,.05);
    box-shadow: 1px 5px 24px 0 rgba(124,77,255,.05);
    border-width: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
    background-color: #fff;
    border-radius: 13px!important;
}
.theme--light.v-application {
    color: rgba(0, 0, 0, 0.87);
}

@media screen and (max-width:767px) {
  .content-personal {
    max-width: 90% !important;
  }
  .content-personal__header {
    flex-direction: column;
  }
}
</style>
