<template>
  <div>
    <h3 class="font-weight-black ml-1 b">Заявление на повышение квалификации: {{ name_group }}</h3>
    <v-card v-if="!success">
      <v-card-text>
        <v-container>
          Нажав "Подать заявление":
          <ol>
            <li>
              Будет создано заявление на повышение квалификации с вашими персональными данными;
            </li>
            <li>
              Далее вы будете перенаправлены на страницу подачи документов, где в том числе вам будет необходимо скачать сформированный шаблон заявления,
              подписать его и загрузить подписанный экземпляр.
            </li>
            <li>
              После проверки документов вы будете допущены к обучению. Об этих и других изменениях вы будете проинформированы по email;
            </li>
          </ol>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-btn
            large
            :disabled="!group_id"
            @click="sendProposal()"
          >
            Подать заявление
          </v-btn>
          <v-container v-if="error">
            <v-alert
              type="error"
            >
              {{ error }}
            </v-alert>
          </v-container>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-alert
      v-else
      type="success"
    >
      <v-container>
        Ваше заявление успешно создано.<br><br>
        Сейчас вы будете перенаправлены на страницу заполнения документов.
      </v-container>
    </v-alert>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import router from '@/router'

export default {
  data() {
    return {
      success: false,
      error: false,
      name_group: this.$route.query['name_group'],
      sposob: this.$route.query['sposob'] || null,
      group_id: this.$route.query['group_id'] || 0
    }
  },
  computed: {
    ...mapState('app', ['user', 'profile'])
  },
  mounted() {
    this.loadConsultant()
  },
  methods: {
    ...mapActions('app', ['GET_INFO_PROFILE', 'CREATE_EDU_PROPOSAL_VIDEO']),
    /**
     * Запрашивает профиль консультанта
     * Если аноним, то профиль не заполнится
     */
    loadConsultant() {
      if (localStorage.getItem('token')) {
        this.GET_INFO_PROFILE()
      }
    },
    sendProposal() {
      this.error = false
      this.CREATE_EDU_PROPOSAL_VIDEO(this.group_id).then(
        (response) => {
          this.success = true
          setTimeout(() => router.push({ name: 'docs' }), 5000)
        }
      ).catch(
        (error) => {
          this.error = error.response.data.message
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.b {
  color: #333 !important;
}
</style>
