<template>
  <v-app>
    <v-card class="mx-auto content-personal" width="100%" style="padding:30px; margin:30px;" :flat='true'>
      <v-card-title>
        <h1 class="switch__current-lang">
          <v-chip class="mr-2 ml-2" dark label style="max-width:168px; margin-bottom:20px;" color="#15336d" @click="locationUser('/org')">
            <v-icon left>mdi-arrow-left</v-icon>
            назад
          </v-chip>
          <br><br>
        </h1>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formIsValid">
          <v-row>
            <v-list>
              <v-list-item>
                <v-list-item-avatar
                  height="100px"
                  width="100px"
                  class="avatar">
                  <v-img v-bind:src="avatar_img"></v-img>
                  <div
                    class="description-upload-photo">
                    <span>t</span>
                    <input
                      type="file"
                      @change="onUploadFile"
                      accept="image/gif, image/jpeg, image/png"
                    />
                  </div>
                </v-list-item-avatar>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line">
                    <v-list-item-title
                      class="title">{{ name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>

                    <template v-for="(item, index) in roles">
                      <v-chip
                        :key="index"
                        class=""
                        x-small>
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-skeleton-loader>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="name"
                  :label="nameLabel"
                  :rules="[rules.required]"
                  required>
                </v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="web_page"
                  :label="surNameLabel"
                  required></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="4">
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="manager"
                  :label="patronymicLabel"
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>

            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="phone"
                  :label="phoneLabel"
                  :rules="[rules.required]"
                  prepend-icon="mdi-phone"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="email"
                  label="E-mail"
                  :rules="[rules.required]"
                  prepend-icon="mdi-mail"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="org_head"
                  label="Руководитель организации"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="address"
                  :label="addressLabel"
                  :rules="[rules.required]"
                  prepend-icon="mdi-location"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="post_address"
                  label="Почтовый адрес"
                  :rules="[rules.required]"
                  prepend-icon="mdi-location"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols="6">
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="city"
                  label="Город"
                  :rules="[rules.required]"
                  prepend-icon="mdi-location"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="kpp"
                  label="ИНН"
                  :rules="[rules.required]"
                  prepend-icon="mdi-location"></v-text-field>
              </v-skeleton-loader>
            </v-col>
            <v-col cols='6'>
              <v-skeleton-loader
                :loading="loading"
                height="94"
                type="list-item-two-line">
                <v-text-field
                  v-model="bank"
                  label="Банковские реквизиты"
                  :rules="[rules.required]"
                  required
                  prepend-icon="mdi-location"></v-text-field>
              </v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row v-if="!formIsValid">
            <v-alert type="error">Есть незаполненные поля</v-alert>
          </v-row>
          <v-row v-if="message_from_save">
            <v-alert type="success">{{ message_from_save }}</v-alert>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!formIsValid"
          @click="save_btn"
        >Сохранить
        </v-btn>
        <br><br>
      </v-card-actions>
    </v-card>

  </v-app>
</template>
<script>
import {validationMixin} from 'vuelidate'
import {required, maxLength, email} from 'vuelidate/lib/validators'
import {parserDate, parserString} from '../../helpers/parserDate'
import user from '../../assets/user.png'
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'PersonalData',
  mixins: [validationMixin],
  data: () => ({
    formIsValid: true,
    currentRang: null,
    title: 'This will be the title',
    boilerplate: true,
    loading: false,
    name: 'Иван',
    manager: '',
    address: '',
    web_page: '',
    loginChecked: true,
    roles: [],
    avatar_img: user,
    email: 'ivan@atknin.ru',
    phone: '+79151322437',
    changeLanguage: '',
    model: null,
    academic_rank: null,
    currentDegree: null,
    currentOrganization: null,
    org_head: '',
    date: new Date().toISOString().substr(0, 10),
    menu2: false,
    head_info: '',
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'Min 8 characters',
    },
    edit: false,
    rang: null,
    organizations: null,
    fullRangArray: null,
    fullDegreeArray: null,
    fullOrganizationArray: [],
    loaded: false,
    post_address: '',
    kpp: '',
    city: '',
    bank: '',
    message_from_save: ''
  }),
  validations: {
    name: { required, maxLength: maxLength(10) },
    email: { required, email },
    select: { required },
    // checkbox: { checked: (val) =>  val },
    city: { required },
    address: { required },
    post_address: { required },
    phone: { required },
    kpp: { required },
    manager: { required },
    org_head: { required }
  },
  computed: {
    ...mapState('app', ['user', 'profile']),
    ...mapGetters([]),
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      return errors;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    surNameLabel() {
      return 'Сайт'
    },
    scienceOrganizationLabel() {
      return ''
    },
    nameLabel() {
      return 'Наименование'
    },
    patronymicLabel() {
      return 'Менеджер'
    },
    hint() {
      return 's'
    },
    birthDayLabel() {
      return ''
    },
    phoneLabel() {
      return 'Телефон'
    },
    addressLabel() {
      return 'Адрес'
    },
    scienceRankLabel() {
      return ''
    },
    scienceDegreeLabel() {
      return ''
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      return errors;
    },

  },
  mounted() {
    console.log("Loading")
    this.GET_INFO_EDUCATION()
      .then(response => {
        this.name = this.profile.user.name
        this.manager = this.profile.user.manager
        this.address = this.profile.user.adress
        this.web_page = this.profile.user.web_page
        this.email = this.profile.user.email
        this.phone = this.profile.user.phone
        this.org_head = this.profile.user.org_head
        this.post_address = this.profile.user.post_address
        this.kpp = this.profile.user.kpp
        this.city = this.profile.user.city
        this.bank = this.profile.user.bank
        console.log(this.profile.user)
      })
  },

  created() {
    // this.loading = true;
  },
  methods: {
    ...mapActions('app', [
      'LOAD_PHOTO',
      'UPDATE_EDUCATION',
      'LANGUAGE_SWITCH',
      'GET_ACADEMIC_DEGREE',
      'GET_ACADEMIC_RANK',
      'ORGANIZATION_HEADERS',
      'GET_INFO_EDUCATION',
      'logged'
    ]),
    ...mapMutations(['SET_APP_LANGUAGE', 'UPDATE_NAME_USER']),
    locationUser(url) {
      window.location.href = url
    },
    async uploadDataUser(lang) {

      try {
        await this.logged({token: localStorage.token});
        const rang = await this.GET_ACADEMIC_RANK(lang);
        const degree = await this.GET_ACADEMIC_DEGREE(lang)

        const organizations = await this.ORGANIZATION_HEADERS();

        this.fullRangArray = rang.data;
        this.fullOrganizationArray = organizations.data;
        this.fullDegreeArray = degree.data;

        this.rang = rang.data.map((item) => item.title);
        this.organizations = organizations.data.map((item) => item.name);

        if (this.GETTERS_LOGIN) {
          this.edit = true;

          const data = await this.GET_PERSONAL_DATA();

          this.email = data.username;
          this.roles = data.profiles[0].role;
          this.last_name = data.profiles[0].individual.last_name;
          this.phone = data.profiles[0].individual.phone_number;
          this.name = data.profiles[0].individual.first_name;
          this.middle_name = data.profiles[0].individual.middle_name || '';
          this.currentDegree = data.profiles[0].individual.academic_degree;
          this.currentRang = data.profiles[0].individual.academic_rank;
          this.currentOrganization = data.profiles[0].individual.organization;
          const currentDate = data.profiles[0].individual.birth_date
            && parserString(data.profiles[0].individual.birth_date);
          this.date = currentDate || '';
          this.avatar_img = data.profiles[0].individual.photo
            ? data.profiles[0].individual.photo : this.avatar_img;
        }
        this.loading = false;
      } catch (ex) {
        this.loginChecked = false;
        console.log('ex ---> ', ex);
      }
    },
    async onUploadFile(e) {

      const file = e.target.files || e.dataTransfer.files;
      console.log('ewwe', file)
      const blob = new Blob([file[0]], {type: ['image/jpg', 'image/jpeg', 'image/png']});
      const files = new FormData();
      files.append('img', blob);
      files.append('id', this.GETTERS_LOGIN);
      files.append('name', file[0].name);

      await this.LOAD_PHOTO(files)
        .then(res => {

        })


    },
    get_params() {
      return {
        data: {
          id: this.GETTERS_LOGIN,
          name: this.name,
          manager: this.manager,
          adress: this.address,
          web_page: this.web_page,
          phone: this.phone,
          email: this.email,
          org_head: this.org_head,
          post_address: this.post_address,
          kpp: this.kpp,
          city: this.city,
          bank: this.bank
        }
      };
    },
    async save_btn() {
      this.boilerplate = true;
      this.loaded = true
      const _messageFromSave = (text = '') => this.message_from_save = text

      _messageFromSave('')

      await this.UPDATE_EDUCATION(this.get_params()).then(
        (res) => {
          if (res.status === 200) {
            this.loaded = false
            _messageFromSave('Данные успешно сохранены')
            setTimeout(_messageFromSave, 4000)
          }

        }).catch(
        (e) => {
          this.dialog = true;
          this.loaded = false;
          this.info = `${e}`;
          this.$messageHelper(`${e}`, 'error', 3000)
        })

    },
    clear() {
      this.$v.$reset();
      this.name = '';
      this.email = '';
      this.select = null;
      this.checkbox = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.description-upload-photo {
  display: none;
  position: absolute;
  background: black;
  color: white;
  opacity: 0.65;
  top: 45px;
}

.avatar:hover .description-upload-photo {
  display: block;
}

.upload {
  opacity: 0;
}

.description-photo {
  top: -20px;
}

input[type=file] {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.content-personal {
  -webkit-box-shadow: 1px 5px 24px 0 rgba(124, 77, 255, .05);
  box-shadow: 1px 5px 24px 0 rgba(124, 77, 255, .05);
  border-width: 0;
  -webkit-transition: all .2s;
  transition: all .2s;
  background-color: #fff;
  border-radius: 13px !important;
}

.theme--light.v-application {
  color: rgba(0, 0, 0, 0.87);
}


@media screen and (max-width: 767px) {
  .content-personal {
    max-width: 90% !important;
  }
  .content-personal__header {
    flex-direction: column;
  }
}
</style>
