<template>
  <v-row>
    <v-col cols="9">
      <h4>Статус: оплата обучения</h4>
      <v-alert prominent outlined color="#191654">
        <v-row align="center">
          <v-col class="grow">
            <span v-if="!payment">Пожалуйста, сделайте оплату за обучение</span>
            <span v-else class="success">Вы уже оплатили обучение</span>
            <br><br>
            <ProposalAndGroupComponent></ProposalAndGroupComponent>
          </v-col>
          <v-col class="shrink">
            <v-btn
              class="success"
              small
              :disabled="payment"
              @click="$router.push({name: 'payments'})"
            >
              Оплатить обучение
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import AttestatComponent from '@/components/common/AttestatComponent'
import ProposalAndGroupComponent from '@/components/common/ProposalAndGroupComponent'

export default {
  components: { AttestatComponent, ProposalAndGroupComponent },
  props: {
    status: { required: true, type: Number }
  },
  data() {
    return {
      payment: false
    }
  },
  computed: {
    ...mapState('app', ['profile'])
  },
  mounted() {
    this.CHECK_PAYMENT_FROM_CONSULTANT({ type_of_pay: this.profile.proposal.form.type === 'high' ? 'payment_high' : 'payment_learning'}).then(
      (response) => this.payment = response
    )
  },
  methods: {
    ...mapActions('app', ['CHECK_PAYMENT_FROM_CONSULTANT'])
  }
}
</script>
