<template>
  <div class="page mt-5">
    <v-row justify="center">
      <div v-if="linkLicense">
        <a :href="linkLicense" download target="_blank">
          <img
            :src="link_file('/media/library/pdf_license.png')"
            width="150px"
            alt="лицензия"
            title="лицензия об образовательной деятельности"
          >
        </a>
        <br>
        <span class="small">до: {{ profile.user.sert_stop }}</span>
        <br>
        <br>
      </div>
    </v-row>
    <v-row>
      <v-card
        color="lighten-1"
        class="mb-4"
        elevation="0"
      >
        Пожалуйста загрузите следующие документы:
        <v-list-item five-line>
          <v-list-item-content>
            <v-card
              v-for="doc in user_files"
              :key="doc.key"
              class="pa-2 ma-1"
              outlined
            >
              <template>
                <v-icon
                  v-if="doc.is_uploaded"
                  color="green darken-2"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
                {{ doc.type }}
                <a
                  v-if="doc.is_uploaded"
                  :href="link_file(doc.url)"
                  target="_blank"
                  download=""
                  style=""
                >
                  <v-chip
                    v-if="doc.url"
                    small
                    label
                    @click=""
                  >
                    <v-icon small>mdi-download</v-icon>
                    Скачать
                  </v-chip>
                </a>
              </template>

              <div class="mt-2">
                <v-file-input
                  v-if="!doc.is_uploaded"
                  ref="zayvfile"
                  v-model="files"
                  label="Выберите файл"
                  outlined
                  class="mt-1"
                  dense
                  @change="upload(doc.key)"
                ></v-file-input>
              </div>
              <small v-for="f in user_files[doc.key]" class="ml-4">
                <v-icon x-small>mdi-arrow-right</v-icon>
                {{ f.name }} <br>
              </small>
            </v-card>

          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-row>
    <v-row>
      <v-simple-table style="width:100%;">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
              </th>
              <th class="text-left">
                Название
              </th>
              <th class="text-left">
                Ссылка
              </th>
              <th class="text-left">
                Дата добавления
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="file in user_files"
              :key="file.key"
            >
              <td v-if="file.is_uploaded">
                <v-icon class="mк-1">
                  mdi-folder
                </v-icon>
              </td>
              <td v-if="file.is_uploaded">{{ file.type }}</td>
              <td v-if="file.is_uploaded">
                <v-btn
                  link
                  small
                  outlined
                  :href="link_file(file.url)"
                >
                  Скачать
                </v-btn>
              </td>
              <td v-if="file.is_uploaded">{{ file.added_date }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UploadService from '../../services/UploadFilesService'
import { base_url } from '@/services/base_url'

export default {
  data() {
    return {
      unsubscribe: null,
      files: [],
      dialog: false,
      linkLicense: false
    }
  },
  computed: {
    ...mapState('app', ['user', 'profile', 'user_files']),
    color_success() {
      const color = {
        persdata: 'lighten-4',
        zayvfile: 'lighten-4',
        diplomfile: 'lighten-4',
        trudovaya: 'lighten-4'
      }

      if (this.user_files.persdata) {
        color.persdata = 'green lighten-5'
      }
      if (this.user_files.zayvfile) {
        color.zayvfile = 'green lighten-5'
      }
      if (this.user_files.diplomfile) {
        color.diplomfile = 'green lighten-5'
      }
      if (this.user_files.trudovaya) {
        color.trudovaya = 'green lighten-5'
      }

      return color
    }
  },
  mounted() {
    this.GET_INFO_EDUCATION().then(
      (response) => {
        this.ORG_FILES(this.profile.user.id).then(
          (response) => {
            const license = response.find((doc) => doc.key === 'license')

            this.linkLicense = license ? this.link_file(license.url) : false
          }
        )
      })
  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess', 'GET_ZAYAVLEMIYE', 'GET_INFO_PROFILE',
      'ORG_FILES', 'GET_SCHET_POVYSHENIE', 'FINISH_UPLOAD', 'GET_INFO_EDUCATION']),
    download_zayav() {
      window.open('https://atknin.com/media/vfpg_1.odt', '_blank')
    },
    upload(type) {
      this.progress = 0
      UploadService.upload_org(this.files, this.profile.user.id, type, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
        .then((response) => {
          this.ORG_FILES(this.profile.user.id)
          this.files = null
        })
        .catch(() => {
          this.progress = 0
          this.files = null
        })

    },
    download(url) {
      window.open(url, '_blank')
    },
    download_zayavlenie() {
      this.GET_ZAYAVLEMIYE()
    },
    finish_upload(key) {
      this.FINISH_UPLOAD({ type: key, id: this.profile.user.id })
        .then((response) => {
          this.ORG_FILES(this.profile.user.id)
        })
    },
    download_schet_povyshenie() {
      this.GET_SCHET_POVYSHENIE({ type: this.pay_update_qualification, id: this.profile.user.id })
    },
    path(val) {
      location.href = val
    },
    link_file(url) {
      url = url.slice(1)

      return `${base_url()}${url}`
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
