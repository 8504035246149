<template>
  <div class="page mt-5">
    <v-row><h4 class="ma-1 mb-3 mt-5">Счета на оплату</h4></v-row>
    <v-row justify="center">
      <v-col
        cols="6"
      >
        <v-card>
          <v-card-title>
            Заявка на получение счета на оплату
          </v-card-title>
          <v-card-text v-if="org && !errorOpenPaymentInvoice && !success">
            <p>{{ org.name }}</p>
            <p>АДРЕС: {{ org.adress }}</p>
            <p>ИНН: {{ org.kpp }}</p>
            <p>СЧЁТ: {{ org.bank }}</p>
            <v-textarea
              v-model="text"
              solo
              label="Если данных недостаточно, укажите здесь все реквизиты"
            >
            </v-textarea>
            <v-alert
              border="right"
              color="blue-grey"
              dark
            >
              <ul>
                <li> После нажатия кнопки "отправить" указанные вами данные уйдут на проверку;</li>
                <li> Далее в течение 2-x рабочих дней на электронную почту организации {{ org.email }} поступит письмо с прикрепленным счетом для оплаты;</li>
              </ul>
            </v-alert>
          </v-card-text>
          <v-card-text v-else-if="errorOpenPaymentInvoice">
            <v-alert
              border="right"
              color="error"
            >
              Данный сервис сейчас не работает. Приносим свои извинения.
            </v-alert>
          </v-card-text>
          <v-card-text v-else-if="success">
            <v-alert
              border="right"
              color="success"
            >
              Ваша заявка на получение счета на оплату создана!
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="org && !errorOpenPaymentInvoice && !success"
              color="success"
              text
              @click="makePaymentInvoiceForOrg()"
            >
              Отправить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      errorOpenPaymentInvoice: false,
      text: '',
      org: null,
      success: false
    }
  },
  beforeMount() {
    this.GET_ORGANISATION_INFO().then(
      (response) => this.org = response.user
    )
  },
  methods: {
    ...mapActions('app', ['GET_ORGANISATION_INFO', 'MAKE_PAYMENT_INVOICE_ORG']),
    makePaymentInvoiceForOrg(text) {
      this.MAKE_PAYMENT_INVOICE_ORG({ text: text }).then(
        () => this.success = true
      ).catch(
        (error) => this.errorOpenPaymentInvoice = error.response
      )
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
