<template>
  <v-row>
    <v-col cols="9">
      <h4>Статус: экзамен сдан</h4>
      <v-alert prominent outlined color="#191654">
        <v-row align="center">
          <v-col class="grow">
            Поздравляем!!!
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AttestatComponent from "@/components/common/AttestatComponent";

export default {
  components: { AttestatComponent },
  props: {
    status: { required: true, type: Number }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState("app", ["user", "profile",])
  },
  created() {},
  methods: {
    ...mapActions("app", [])
  }
}
</script>
