<template>
  <div class="page mt-5 container">
    <router-link
      :to="{ name: 'consultant' }"
    >
      <v-chip
        class="mr-2 "
        dark
        outlined
        label
        style="max-width:168px; margin-bottom:20px;"
        color="primary"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        личный кабинет
      </v-chip>
    </router-link>
    <v-row>
      <v-col cols="12">
        <h3 class="primary-color mb-5"> Мероприятия палаты</h3>

        <v-row>
          <v-col v-for="event in events" :key="event.event_id" cols="12">
            <v-card
              class="mx-auto"
            >
              <v-card-subtitle>
                <h5>{{ event.title }}</h5>
                <v-icon small> mdi-calendar</v-icon>
                {{ event.event_date }}
              </v-card-subtitle>
              <v-list>
                <v-list-item
                  v-for="speaker in event.speakers"
                  :key="speaker.id"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="speaker.fio"></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-avatar
                    size="30"
                    class="ml-2 mr-1"
                  >
                    <v-img
                      contain
                      height="200px"
                      :src="speaker.img"
                    >
                    </v-img>
                  </v-list-item-avatar>
                </v-list-item>
              </v-list>
              <v-card-actions>

                <v-btn
                  v-if="!is_auth && !event.already_requested"
                  color="primary lighten-2"
                  text
                  @click="dialog=true; selected_event=event.event_id"
                >
                  Зарегистрироваться
                </v-btn>
                <v-btn
                  v-if="is_auth && !event.already_requested"
                  color="primary lighten-2"
                  text
                  @click="registerToEvent(event.event_id)"
                >
                  Записаться
                </v-btn>
                <v-btn v-if="event.already_requested && !event.is_allowed" text disabled>Вы уже записались</v-btn>
                <v-btn v-if="event.already_requested && event.is_allowed" text disabled>Вы приглашены</v-btn>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary lighten-2"
                  text
                  @click="path('/event?id='+event.event_id)"
                >
                  Подробнее
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

      </v-col>

    </v-row>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Регистрация нового консультанта</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="name"
                      label="Имя"
                      required
                    >
                    </v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="4">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Фамилия"
                      required
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="4">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Отчество"
                      required
                    >
                    </v-text-field>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="phone"
                      label="Телефон"
                      required
                      prepend-icon="mdi-phone"
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="email"
                      label="E-mail"
                      required
                      prepend-icon="mdi-mail"
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="inn"
                      label="ИНН"
                      required
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="snils"
                      label="СНИЛС"
                      required
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="address"
                      label="Адрес"
                      required
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-autocomplete
                      v-model="selectedRegion"
                      :items="regions"
                      item-value="id"
                      color="white"
                      item-text="name"
                      label="Регион"
                      return-object
                    ></v-autocomplete>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-text-field
                      v-model="organization"
                      label="Организация"
                      required
                    ></v-text-field>
                  </v-skeleton-loader>
                </v-col>
                <v-col cols="6">
                  <v-skeleton-loader
                    :loading="loading"
                    height="94"
                    type="list-item-two-line"
                  >
                    <v-select
                      v-model="selectedEducation"
                      :items="['Экономическое','Экономическое среднее','Юридическое','Юридическое среднее','Непрофильное']"
                      label="Тип образования"
                    ></v-select>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Закрыть
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="sign_up(selected_event)"
            >
              Зарегистрироваться
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      events: [],
      isLoadingSave: false,
      loading: false,
      dialog: false,
      name: '',
      middle_name: '',
      address: '',
      regions: [],
      last_name: '',
      email: '',
      phone: '',
      selectedRegion: { id: null, name: null },
      selectedEducation: '',
      inn: '',
      snils: '',
      organization: '',
      edu_type: '',
      is_auth: false,
      selected_event: 0
    }
  },
  computed: {
    ...mapState('app', ['user'])
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.is_auth = true
    }
    this.get_events(this.is_auth)
    this.GET_REGIONS().then((response) => {
      console.log(response)
      this.regions = response.items
    })
  },
  beforeDestroy() {
  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess', 'GET_EVENTS', 'GET_REGIONS', 'SIGN_UP_TO_EVENT', 'GET_EVENTS_AUTH', 'REGISTER_TO_EVENT']),
    download_zayav() {
      window.open('https://atknin.com/media/vfpg_1.odt', '_blank')
    },
    path(val) {
      location.href = val
    },
    get_events(is_auth) {
      if (is_auth) {
        this.GET_EVENTS_AUTH().then(
          (res) => this.events = res.items
        )
      } else {
        this.GET_EVENTS().then(
          (res) => this.events = res.items
        )
      }
    },
    sign_up(event_id) {
      this.SIGN_UP_TO_EVENT(this.get_params(event_id)).then((res) => {
        if (res.status === 200) {
          const idx = this.events.findIndex((o) => o.event_id === event_id)

          this.events[idx].already_requested = true
          const tmp_events = this.events

          this.events = []
          this.events = tmp_events
          this.dialog = false
        }
        console.log(res)
      })
    },
    /**
     * Регистрация на мероприятия для авторизированных консультантов
     * @param event_id: int
     */
    registerToEvent(event_id) {
      this.REGISTER_TO_EVENT({ event_id: event_id }).then(
        (res) => {
          const idx = this.events.findIndex((o) => o.event_id === event_id)

          this.events[idx].already_requested = true
          const tmp_events = this.events

          this.events = []
          this.events = tmp_events
          this.dialog = false
        })
    },
    get_params(event_id) {
      return {
        name: this.name,
        middle_name: this.middle_name,
        address: this.address,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        inn: this.inn,
        snils: this.snils,
        organization: this.organization,
        edu_type: this.edu_type,
        event_id: event_id
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
