<template>
  <div class="page mt-0">
    <div v-if="resultsPoll">
      <v-dialog
        v-model="resultPoll"
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <br>
          <v-btn
            color="success"
            v-bind="attrs"
            v-on="on"
          >
            "{{ resultsPoll.name }}"
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="resultPoll = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Закрыть</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                class="green"
                text
                :disabled="!AllQuestionsAnswer"
                @click="CreateResultFromUser"
              >
                Отправить ответы
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-list>
            <v-container>
              <h4>{{ activePolls[0].name }}</h4>
            </v-container>
          </v-list>
          <v-list v-if="activePolls[0].description">
            <v-container>
              <pre>{{ activePolls[0].description }}</pre>
            </v-container>
          </v-list>
          <v-divider></v-divider>
          <v-list
            three-line
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-card
                  v-for="(que, index) in activePolls[0].questions"
                  :key="que.id"
                >
                  <template>
                    <v-container fluid>
                      <br>
                      <div><pre>{{ que.name }}</pre></div>
                      <br>
                      <div v-if="que.file">
                        <a :href="que.file" download target="_blank">
                          <v-chip><v-icon>mdi-eye-settings</v-icon> читать файл</v-chip>
                        </a>
                      </div>
                      <br>
                      <v-radio-group
                        v-model="resultsPoll.questions[index].answer"
                        row
                      >
                        <v-radio
                          v-for="ans in que.answers"
                          :key="ans.id"
                          :label="ans.name"
                          :value="{ id: ans.id, name: ans.name }"
                          @click="updateAllQuestions"
                        >
                        </v-radio>
                      </v-radio-group>
                      <br>
                      <br>
                    </v-container>
                  </template>
                </v-card>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  class="green"
                  text
                  :disabled="!AllQuestionsAnswer"
                  @click="CreateResultFromUser"
                >
                  Отправить ответы
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-dialog>
    </div>

    <h3 v-if="profile" class="primary-color mb-3">
      {{ profile.user.last_name }} {{ profile.user.name }}
      {{ profile.user.middle_name }}
    </h3>
    <upload-docs-status-view v-if="status.slug==='need_docs' || status.slug==='need_personal'" :status="status.number"></upload-docs-status-view>
    <check-docs-component v-else-if="status.slug==='check_docs'"></check-docs-component>
    <need-payment-status-view v-else-if="status.slug==='payment_first'" :status="status.number"></need-payment-status-view>
    <education-status-view v-else-if="status.slug==='teach' || status.slug==='exam'" :status="status.number"></education-status-view>
    <exam-failed-status-view v-else-if="status.slug==='exam_bad'" :status="status.number"></exam-failed-status-view>
    <ExamOKStatusView v-else-if="status.slug==='exam_ok'" :status="status.number"></ExamOKStatusView>
    <need-level-up-status-view v-else-if="status.slug==='need_high'"></need-level-up-status-view>
    <EducationSecondStatusView v-else-if="status.slug==='education_second'" :status="status.number"></EducationSecondStatusView>
    <ConsultantStatus v-else-if="status.slug==='consultant'" :status="status.number"></ConsultantStatus>
    <template v-else>
      <div v-if="profile">{{ profile.user.status.name }}</div>
    </template>
    <br>
    <h5>Пройденные курсы и обучения</h5>
    <v-simple-table>
      <thead>
        <tr>
          <th>Группа</th>
          <th>Программа</th>
          <th>Дата выпуска</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="release in releases"
          :key="release.id"
        >
          <td>{{ release.group.name }}</td>
          <td>{{ release.group.program.name }}</td>
          <td>{{ release.date }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import UploadService from '../../services/UploadFilesService'
import CreatedStatusView from '../../components/common/CreatedStatusView.vue'
import UploadDocsStatusView from '../../components/common/UploadDocsStatusView.vue'
import NeedPaymentStatusView from '../../components/common/NeedPaymentStatusView.vue'
import EducationStatusView from '../../components/common/EducationStatusView.vue'
import ExamFailedStatusView from '../../components/common/ExamFailedStatusView.vue'
import PaymentCompleteStatusView from '../../components/common/PaymentCompleteStatusView.vue'
import NeedLevelUpStatusView from '../../components/common/NeedLevelUpStatusView.vue'
import CheckDocksComponent from '@/components/common/CheckDocksComponent'
import ExamOKStatusView from '@/components/common/ExamOKStatusView'
import EducationSecondStatusView from '@/components/common/EducationSecondStatusView'
import ConsultantStatus from '@/components/common/ConsultantStatus'

export default {
  components: {
    'created-status-view': CreatedStatusView,
    'need-payment-status-view': NeedPaymentStatusView,
    'education-status-view': EducationStatusView,
    'exam-failed-status-view': ExamFailedStatusView,
    'payment-complete-status-view': PaymentCompleteStatusView,
    'need-level-up-status-view': NeedLevelUpStatusView,
    'upload-docs-status-view': UploadDocsStatusView,
    'check-docs-component': CheckDocksComponent,
    ExamOKStatusView,
    EducationSecondStatusView,
    ConsultantStatus
  },
  data() {
    return {
      unsubscribe: null,
      info_profile: {},

      // status: this.profile ? this.profile.user.status : 'need_personal',
      files: null,
      pay_update_qualification: 'individual',
      progress: 0,
      cards: [],

      lists: [
        {
          id: 0,
          label: 'Templates (Apps Presets)',
          description:
            'Web apps fully integrated with Firebase (blog, chat, chrome extension, etc)'
        }
      ],

      isLoadingSave: false,
      experience: {
        trud: false,
        ip: false
      },
      doc_sdal: false,
      // add variables
      addDialog: false,
      addToList: {},
      newCardText: '',
      apply_org: 'Институт Бизнес-Образования (ЧОУ ДПО)',
      newCardName: '',

      // delete variables
      deleteDialog: false,
      cardToDelete: null,
      isLoadingDelete: false,
      files_uploaded: {},
      need_upload_docs: false,
      activePolls: [],
      resultPoll: false,
      resultsPoll: null,
      AllQuestionsAnswer: false,
      releases: []
    }
  },
  computed: {
    ...mapState('app', ['user', 'profile', 'user_files']),

    zayav_link() {
      return `/request-second/?attest=${this.profile.user.attestat_number}`
    },
    show_badge() {
      return true
    },
    status() {
      return this.profile ? this.profile.user.status : { number: 0, slug: '', name: '' }
    }
  },
  beforeMount() {
    this.GET_INFO_PROFILE().then((response) => {
      const dateEnd = this.profile.user.date_end.split('.')
      const isAttestat = new Date() < new Date(dateEnd[2], dateEnd[1], dateEnd[0])

      if (!this.profile.user.is_all_docs_uploaded) {
        // Если не загружены все документы
        // eslint-disable-next-line @typescript-eslint/camelcase
        let _no_proposal_from_user = 0

        this.GET_PROPOSALS_FROM_USER().then((response) => {
          _no_proposal_from_user = response ? response : 0

          if (!_no_proposal_from_user) {
            // когда нет заявки -> отправляем создавать заявку

            if (this.profile.user.attestat_number && isAttestat) {
              // если есть аттестат
              // this.$router.push({ name: 'request_second', query: { no_proposal: 1 } })
              console.log('')
            } else {
              // если нет аттестата
              this.$router.push({ name: 'request_first', query: { no_proposal: 1 } })
            }

          } else {
            this.need_upload_docs = true
            this.$router.push({ name: 'docs' })
          }
        })
      }

      this.USER_FILES(this.profile.user.id)

      if (this.profile.user.attestat_number && isAttestat) {
        this.GET_ACTIVE_POLLS().then(
          (response) => {
            if (response.length) {
              this.activePolls = response
              this.resultsPoll = {}
              for (const key in response[0]) {
                const item = response[0][key]
                let rezkey

                if (key === 'questions') {
                  rezkey = []
                  for (const que in item) {
                    rezkey[que] = { question: { id: item[que].id, name: item[que].name }, answer: 0 }
                  }
                } else {
                  rezkey = response[0][key]
                }

                this.resultsPoll[key] = rezkey
              }
              this.resultPoll = true
            }
          }
        )
      }
      this.GET_RELEASES_CONSULTANT().then(
        (response) => this.releases = response
      )
    })
  },

  beforeDestroy() {
  },
  methods: {
    ...mapActions('app', [
      'showError',
      'showToast',
      'showSuccess',
      'GET_ZAYAVLEMIYE',
      'GET_INFO_PROFILE',
      'USER_FILES',
      'GET_SCHET_POVYSHENIE',
      'FINISH_UPLOAD',
      'GET_PROPOSALS_FROM_USER',
      'GET_ACTIVE_POLLS',
      'CREATE_RESULT_FROM_USER',
      'GET_RELEASES_CONSULTANT'
    ]),
    upload(type) {
      this.progress = 0
      UploadService.upload(this.files, this.profile.user.id, type, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total)
      })
        .then((response) => {
          this.USER_FILES(this.profile.user.id)
          this.files = null
        })
        .catch(() => {
          this.progress = 0
          this.files = null
        })
    },
    download(url) {
      window.open(url, '_blank')
    },
    download_zayavlenie() {
      this.GET_ZAYAVLEMIYE()
    },
    finish_upload(key) {
      this.FINISH_UPLOAD({ type: key, id: this.profile.user.id }).then(
        (response) => {
          this.USER_FILES(this.profile.user.id)
        }
      )
    },
    download_schet_povyshenie() {
      this.GET_SCHET_POVYSHENIE({
        type: this.pay_update_qualification,
        id: this.profile.user.id
      })
    },

    openAdd(list) {
      this.addToList = list
      this.addDialog = true
    },
    path(val) {
      location.href = val
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    _allQuestions() {
      if (!this.resultsPoll) {
        return false
      }
      for (const key in this.resultsPoll.questions) {
        if (!this.resultsPoll.questions[key].answer) {
          return false
        }
      }

      return true
    },
    updateAllQuestions() {
      this.AllQuestionsAnswer = this._allQuestions()
    },
    CreateResultFromUser() {
      this.CREATE_RESULT_FROM_USER(this.resultsPoll).then(
        (response) => {
          this.resultPoll = false
          this.resultsPoll = null
          this.AllQuestionsAnswer = false
          this.activePolls = []
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
