<template>
  <div>
    <h3 class="font-weight-black ml-1 b">Заявление на обучение</h3>
    <p class=" ml-1 b">
      <!-- Заполнение формы приводит к регистрации в инфо - ресурсе Палаты Налоговых Консультантов,<br>
      если вы ранее не проходили подготовку, система также автоматически сформирует заявление на обучение -->
      <!-- <br>
      <small>
        <v-icon small>mdi-timer</v-icon>
        время заполнения 5 минут</small> -->
    </p>
    <p v-if="!token" class=" ml-1 b">
      <small>
        <v-btn
          color="primary"
          dark
          small
          @click="dialog=true"
        >
          Войти
        </v-btn>
      </small>
    </p>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="350px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Авторизация</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-text-field
              v-model="auth_email"
              label="E-mail или номер аттестата"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Пароль"
              required
            ></v-text-field>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false; sign_in()"
          >
            Войти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-stepper
      v-model="e6"
      vertical
    >
      <template>
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          <span>
            Выберите регион
          </span>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-sheet class="pl-5 pa-2">
            <p>Выберите регион в котором планируется проходить обучение?</p>
            <v-autocomplete
              v-model="form.region"
              :items="regions"
              outlined
              item-text="name"
              item-value="id"
              style='max-width:500px'
              label="Регион"
            ></v-autocomplete>

          </v-sheet>
          <v-btn
            color="primary"
            :disabled='form.region==null'
            @click="e6 += 1"
          >
            Далее
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 2"
          step="2"

        >
          Образовательная организация
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-autocomplete
            v-model="form.org"
            :items="orgs"
            item-text="name"
            item-value="id"
            outlined
            style='max-width:500px'
            class="ml-5 mt-2"
            label="Организация"
          ></v-autocomplete>
          <v-alert
            icon="mdi-alert"
            v-if='orgs.length === 0'
            class='ma-2'
            prominent
            text
            type="info"
          >
            Информируем Вас, что в выбранном регионе отсутствуют аккредитованные образовательные организации. Вы можете
            выбрать другой регион и пройти обучение в дистанционном формате.
          </v-alert>
          <v-btn
            color="primary"
            @click="e6 = 3"
            :disabled='form.org === null'
          >
            Далее
          </v-btn>
          <v-btn text @click="e6 -= 1">
            Назад
          </v-btn>
        </v-stepper-content>


        <v-stepper-step
          :complete="e6 > 3"
          step="3"
          disabled
          v-if='!questions.previous'
        >
          Программа обучения
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-alert
            icon="mdi-alert"
            v-if='form.online'
            class='ma-2'
            prominent
            text
            type="info"
          >
            Информируем Вас, что в выбранном регионе доступна только онлайн (удаленная) программа подготовки налогового
            консультанта. Перейдите на следующий шаг, если Вы согласны с форматом обучения
          </v-alert>
          <v-autocomplete
            v-model="form.program"
            :items="programs"
            item-text="name"
            v-if='!form.online'
            item-value="id"
            outlined
            style='max-width:500px'
            class="ml-5 mt-2"
            label="Программа"
          ></v-autocomplete>

          <v-btn
            color="primary"
            @click="e6 = 4"
            :disabled='!form.program'
          >
            Далее
          </v-btn>

          <v-btn text @click="e6 -= 1">
            Назад
          </v-btn>

        </v-stepper-content>


        <v-stepper-step
          :complete="e6 > 4"
          step="4"
        >
          Персональные данные
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="pa-5"
          >
            <template v-for='field in fields'>
              <v-row class="">
                <v-col cols="3" class='pa-0'>
                  <v-subheader>{{ field.name }}
                    <small v-if='field.rule.required'>*</small>
                  </v-subheader>
                </v-col>
                <v-col cols="9" class='pa-0'>
                  <v-select
                    v-if='field.key==="education"'
                    :items="educations_list"
                    v-model='form[field.key]'
                    :rules="field.error"
                    outlined
                    dense
                    style='max-width:500px'
                  ></v-select>
                  <v-text-field
                    v-else
                    outlined
                    dense
                    :label="field.label"
                    v-model='form[field.key]'
                    :prefix="field.prefix"
                    :rules="field.error"
                    style='max-width:500px'
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-form>

          <v-checkbox
            v-model="pers_data"
            :label="`Согласен на обработку персональных данных`"
          ></v-checkbox>


          <v-btn
            color="primary"
            @click="e6 += 1; sendProposal()"
            :disabled="!valid"
          >
            Подать заявление
          </v-btn>
          <v-btn text @click="e6 -= stepValue()">
            Назад
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="5">
          Готово
        </v-stepper-step>
        <v-stepper-content step="5">
          <v-card
            v-if="!error206"
            color="grey lighten-3"
            class="mb-12 text-center"
            height="200px"
          >
            <v-icon class="mt-5" color="success" x-large>mdi-check-circle</v-icon>
            <p class="ma-2">
              Ваше заявление принято, на указанный e-mail отправлена инструция для входа.
              <br>Запомните данные - они будут использоваться для входа в Инфо-ресурс
<!--              <br><br>-->
<!--              <router-link :to="{ name: 'docs' }"></router-link>-->
            </p>
          </v-card>
          <v-card
            v-else
            color="red lighten-3"
            class="mb-12 text-center"
            height="200px"
          >
            <v-icon class="mt-5" color="alert" x-large>mdi-alert-circle</v-icon>
            <p class="ma-2 white">
              Ваше заявление не принято.
              <br> Причина: {{ error206 }}
              <br> Совершите вход под под своей регистрацией, а потом заполните заявку.
              <br> Если вы не помните свой пароль, то воспользуйтесь функцией восстановления пароля.
              <br>
            </p>
            <LinkPersonalCabinet/>
          </v-card>
          <v-btn @click="path('/login/')">
            Войти в личный кабинет
          </v-btn>
        </v-stepper-content>
      </template>
    </v-stepper>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import LinkPersonalCabinet from '@/pages/landing/components/LinkPersonalCabinet'
import router from "@/router"

export default {
  components: { LinkPersonalCabinet },
  data() {
    return {
      e6: 1,
      type1: false,
      questions: {
        previous: false,
      },
      valid: false,
      form: {
        educated: false,
        region: null,
        org: null,
        program: null,
        attestat: null,
        name: null,
        online: false,
        group: null
      },
      citt: '',
      dialog: false,
      pers_data: true,
      regions: [],
      programs: [],
      org_groups: [],
      type2: true,
      token: null,
      auth_email: '',
      password: '',
      fields: [
        {
          name: 'E-mail', key: 'email', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
            digits: 7,
            regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
          },
          error: [
            v => !!v || 'Укажите адрес электронной почты',
            v => /.+@.+\..+/.test(v) || 'E-mail не прошел проверку',
          ]
        },
        {
          name: 'Фамилия', key: 'last_name', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
          },
          error: [
            v => !!v || 'Укажите фамилию',
          ]
        },
        {
          name: 'Имя', key: 'name', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
          },
          error: [
            v => !!v || 'Укажите Имя',
          ]
        },
        {
          name: 'Отчество', key: 'middle_name', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
          },
          error: [
            v => !!v || 'Укажите Отчество',
          ]
        },
        {
          name: 'Фактический адрес', key: 'address', label: '', prefix: '', requared: false, type: 'text',
          rule: {
            required: true,
          },
          error: [
            v => !!v || 'Укажите Фактический адрес',
          ]
        },
        {
          name: 'Телефон', key: 'phone', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
            digits: 7,
            regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
          },
          error: [
            v => !!v || 'Укажите ваш телефон',
          ]
        },
        {
          name: 'Образование', key: 'education', label: '', prefix: '', type: 'select',
          rule: {
            required: true,
          },
          error: [
            v => !!v || 'Выберите образование',
          ]
        },
        {
          name: 'Стаж, лет', key: 'staj', label: '', prefix: '', type: 'number',
          rule: {
            required: true,
            digits: 7,
          },
          error: [
            v => !!v || 'Укажите стаж',
          ]
        },
      ],
      orgs: [],
      error206: false,
      no_proposal_from_query: Number(this.$route.query.no_proposal) || 0,
      proposal_from_user: null
    }
  },
  computed: {
    ...mapState('app', ['user', 'profile', 'educations_list'])
  },
  watch: {
    form(val) {
      console.log('Valid ' + this.valid)
      this.$refs.form.validate()
      console.log('Valid ' + this.valid)
    },
    e6(val) {
      //   загружаем список организаций, если обучается впервые
      if (this.form.org) {
        this.load_programs()
        this.load_org_groups(this.form.org)
      }
      if (this.form.region) {
        this.loadOrgs()
        // if (val === 2 && this.orgs.length === 0) {
        //   // this.form.region = null
        // }
      }
      console.log('region ' + this.form.region)
      console.log('Val ' + val)
    }
  },
  mounted() {
    this.GET_INFO_PROFILE().then(
      (response) => {
        this.savePersonalToForm()
        //
        // if (this.profile.user) {
        //   // когда есть пользователь
        //   if (this.no_proposal_from_query === 0 ) {
        //     // и нет флага, что мы уже точно знаем, что нет заявки, то ищем заявку
        //     this.GET_PROPOSALS_FROM_USER().then(
        //       (response) => this.proposal_from_user = response
        //     )
        //   }
        // }
      }
    )
    this.GET_REGIONS().then(
      (res) => this.regions = res.items
    )
    this.valid = false
  },
  methods: {
    ...mapActions('app', ['GET_REGIONS', 'GET_ORGS',
      'GET_PROGRAMS', 'SIGN_UP_CONSULTANT', 'SIGN_IN', 'GET_INFO_PROFILE', 'GET_ORG_GROUPS', 'CREATE_EDU_PROPOSAL', 'GET_PROPOSALS_FROM_USER']),

    load_consultant() {
      if (localStorage.getItem('token')) {
        this.GET_INFO_PROFILE()
          .then((response) => {
            if (response) {
              this.token = true
              this.form['name'] = this.profile.user.name
              this.form['middle_name'] = this.profile.user.middle_name
              this.form['address'] = this.profile.user.address
              this.form['last_name'] = this.profile.user.last_name
              this.form['email'] = this.profile.user.email
              this.form['phone'] = this.profile.user.phone
              this.form['education'] = this.profile.user.education
            }
          })
      }
    },
    savePersonalToForm() {
      this.token = true
      this.form['name'] = this.profile.user.name
      this.form['middle_name'] = this.profile.user.middle_name
      this.form['address'] = this.profile.user.address
      this.form['last_name'] = this.profile.user.last_name
      this.form['email'] = this.profile.user.email
      this.form['phone'] = this.profile.user.phone
      this.form['education'] = this.profile.user.education
    },
    sign_in() {
      this.SIGN_IN({ email: this.auth_email, password: this.password })
        .then((response) => {
          this.load_consultant()
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла ошибка при входе')
        });

    },
    signup(data) {
      this.SIGN_UP_CONSULTANT(data)
        .then((res) => {
          if (res.status === 206) {
            // когда совпадает email или телефон -> поменяется ответ результата подачи заявки
            this.error206 = res.message
          } else {
            setTimeout(router.push({ name: 'docs' }), 5000)
          }
        })
        .catch((e) => {
          this.e6 -= 1
          alert(e)
        })
    },
    load_org_groups(id) {
      this.GET_ORG_GROUPS({ id: id, level: this.form.educated })
        .then(res => {
          let groups = res.items
          groups.forEach(group => {
            group.name = group.name + " (" + group.program + ") начало: " + group.date_start
          })
          this.org_groups = groups
        })
    },
    load_programs() {
      this.GET_PROGRAMS({ id: this.form.org, online: this.form.online, level: 'start' })
        .then(res => {
          this.programs = res.items
        })
    },
    /**
     * Загружает организации в определенном регионе
     * Если нет организаций, то ставит форму обучение = online (не знаю зачем так!!!)
     */
    loadOrgs() {
      this.GET_ORGS({ id: this.form.region })
        .then((res) => {
          this.orgs = res.items
          console.log(res.items)
          if (res.items.length === 0) {
            this.form.online = true
          }
        })
    },
    stepValue() {
      return this.questions.previous ? 2 : 1
    },
    path(val) {
      location.href = val
    },
    sendProposal() {
      if (this.form.educated) {
        this.CREATE_EDU_PROPOSAL({ form: this.form })
          .then((res) => {
          })
          .catch((e) => {
            this.e6 -= 1
            alert(e)
          })
      } else {
        this.signup({ form: this.form })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.b {
  color: #333 !important;
}
</style>
