<template>
  <v-row>
    <v-col cols="9">
      <h4>Статус: Требуется повышение квалификации</h4>
      <v-alert prominent outlined color="#191654">
        <v-row align="center">
          <v-col class="grow">
            Вы можете повысить квалификацию различными способами.<br>
            Для этого нажмите "Повысить квалификацию".
            <br><br>
            <ul class="small">
              <li>
                Если вы проходите обучение в группе, то вам требуется набрать 72 учебных часа.
                Сейчас вы прошли {{ profile.user.hours }} из 72 часов повышения квалификации.
              </li>
              <li>
                Если вы выбрали видеосеминары или дежурство на горячей линии, то часы учитываются индивидуально.
              </li>
            </ul>
          </v-col>
          <v-col class="shrink">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mb-1"
                  dark
                  small
                  color="#191654"
                  v-bind="attrs"
                  v-on="on"
                >
                  Повысить квалификацию
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="pushToEducationSecond('request_second', 'group')">
                  Группы обучения (36, 72ч.)
                </v-list-item>
                <v-list-item
                  v-for="group in groups_others"
                  :key="group.id"
                  @click="pushToEducationSecond('request_second_video', group.status__slug, group.name, group.id)"
                >
                  {{ group.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AttestatComponent from '@/components/common/AttestatComponent'

export default {
  components: { AttestatComponent },
  data() {
    return {
      groups_others: []
    }
  },
  computed: {
    ...mapState('app', ['profile'])
  },
  mounted() {
    this.GET_GROUPS_OTHERS_PNK().then(
      (response) => this.groups_others = response
    )
  },
  methods: {
    ...mapActions('app', ['GET_GROUPS_OTHERS_PNK']),
    pushToEducationSecond(url = 'request_second', path_add = 'group', name_group = '', group_id = 0) {
      this.$router.push({ name: url, query: { sposob: path_add, name_group: name_group, group_id: group_id } })
    }
  }
}
</script>
