<template>
  <v-row>
    <v-col cols="9">
      <h4>Статус: Налоговый консультант</h4>
      <v-alert prominent outlined color="#191654" type="su">
        <v-row align="center">
          <v-col class="grow">
            Вам присвоен номер аттестата: {{profile.user.attestat_number}} <br />
            Поздравляем! Вы стали членом проф. сообщества. Для Вас создан электронный почтовый адрес: {{profile.user.attestat_number}}@pnk.ru <br />
            Общайтесь с коллегами и клиентами через ресурс ПНК. <br />
            Дата получения аттестата: {{profile.user.date_issues}}
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3" v-if="show_badge">
      <v-card
        class="mx-aut mb-8 mt-10"
        dark
        style="background-color: rgb(4, 162, 124)"
      >
        <v-card-title>
          <a href='https://pnk.palata-nk.ru/'>
          <img style="height: 60px" src="../../assets/logo5.svg" alt="..." />
          </a>
          <span class="title font-weight-light">Членство в палате</span>
        </v-card-title>

        <v-card-text class="" v-if="profile.user.attestat_number">
          Номер аттестата:
          <v-chip label light color="white" x-small>
            <b> {{ profile.user.attestat_number }} </b></v-chip
          >
          <br />
          Аттестат действителен до: <i>{{profile.user.date_end}}</i>
        </v-card-text>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-col>
    <v-alert
      border="left"
      color="red"
      dismissible
      type="warning"
      v-if="need_upload_docs"
    >
      <v-row align="center">
        <v-col class="grow">
          Пожалуйста, загрузите необходимые документы
        </v-col>
        <v-col class="shrink">
          <v-btn light color="white" @click="path('/docs')">Загрузить</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { realtime, TIMESTAMP } from "../../firebase";
import uniqueid from "../../utils/uniqueid";
import UploadService from "../../services/UploadFilesService";

export default {
  data() {
    return {};
  },
  props: {
    status: { required: true, type: Number },
  },
  computed: {
    ...mapState("app", ["user", "profile", "user_files"]),
    zayav_link() {
      return `/request-second/?attest=${this.profile.user.attestat_number}`;
    },
    show_badge() {
      return true;
    },
  },
  created() {},
  methods: {
    ...mapActions("app", [
      "showError",
      "showToast",
      "showSuccess",
      "GET_ZAYAVLEMIYE",
      "GET_INFO_PROFILE",
      "USER_FILES",
      "GET_SCHET_POVYSHENIE",
      "FINISH_UPLOAD",
    ]),
  },
};
</script>
