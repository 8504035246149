<template>
  <v-row>
    <v-col cols="9">
      <h4>Статус: экзамен не сдан</h4>
      <v-alert prominent outlined color="#191654">
        <v-row align="center">
          <v-col class="grow">
            К сожалению, Вам не удалось подтвердить квалификацию "Консультант по налогам и сборам". Не расстраивайтесь!
            Вам предоставляется возможность пересдачи в течение 12 месяцев с момента сдачи экзамена.
            По вопросам пересдачи обращаться: Гольцова Анна, тел.: email:
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AttestatComponent from "@/components/common/AttestatComponent";

export default {
  components: { AttestatComponent },
  props: {
    status: { required: true, type: Number },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("app", ["user", "profile", "user_files"]),
  },
  created() {},
  methods: {
    ...mapActions("app", [
      "showError",
      "showToast",
      "showSuccess",
      "GET_ZAYAVLEMIYE",
      "GET_INFO_PROFILE",
      "USER_FILES",
      "GET_SCHET_POVYSHENIE",
      "FINISH_UPLOAD",
    ]),
  },
};
</script>
