<template>
  <div class="page mt-5">
    <v-row>
      <v-row>
        <h4 class="ma-1 mb-3 mt-5">Набор в группу {{ group_number }}
          <template v-if="group.name">/ {{ group.name }}</template>
          <br>
          <v-chip
            color="primary"
            text
            x-small
            label
            outlined
            @click="dialog = true"
          >
            Редактировать группу
          </v-chip>
        </h4>
      </v-row>
      <v-row style="width:100%">
        <v-col>
          <v-row>
<!--            <v-col cols="8">-->
<!--              <v-file-input-->
<!--                label="Загрузить Консультантов Excel"-->
<!--                type="file"-->
<!--                @change="onFileChange"-->
<!--              ></v-file-input>-->

<!--              <v-chip-->
<!--                color="primary"-->
<!--                text-->
<!--                x-small-->
<!--                label-->
<!--                outlined-->
<!--              >-->
<!--                <a :href="link_file('media/programs/xkzm_book1.xlsx')" download="" target="_blank">-->
<!--                  скачать шаблон-->
<!--                </a>-->
<!--              </v-chip>-->

<!--              <v-btn-->
<!--                color="primary"-->
<!--                text-->
<!--                small-->
<!--                label-->
<!--                outlined-->
<!--                class="ma-2"-->
<!--                @click="add_consultants_xlsx()"-->
<!--              >-->
<!--                Добавить консультантов-->
<!--              </v-btn>-->

<!--            </v-col>-->
            <v-col cols="8">

              <v-autocomplete
                v-model="selectedConsultant"
                label="Консультант"
                :items="consultants_from_room"
                item-value="consultant_id"
                item-text="consultant"
                :filter="filterObject"
                clearable
                hint="Список доступных для этой группы консультантов  создается автоматически"
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col>

              <v-chip
                class="ma-2"
                color="primary"
                label
                outlined
                @click="add_consultant()"
              >
                Добавить
                <v-icon right>
                  mdi-add
                </v-icon>
              </v-chip>

            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col cols="6">
              <v-file-input
                v-model="programFile"
                type="file"
                label="Загрузить/обновить программу"
              ></v-file-input>
              <v-chip
                class=""
                color="primary"
                small
                label
                outlined
                @click="upload()"
              >
                отправить
              </v-chip>
            </v-col>
            <v-col cols="6">
              <v-chip
                v-if="group.program_file"
                class="ma-2"
                color="primary"
                label
                outlined
                @click="path_new_window(group.program_file)"
              >
                Текущая программа
                <v-icon right>
                  mdi-download
                </v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row style="width:100%">
        <v-data-table
          style="width:100%"
          :headers="headers"
          :items="group_consultants"
          class="elevation-1 mt-3"
        >
          <template v-slot:item.fio="{ item }">
            {{ item.last_name }} {{ item.name }} {{ item.middle_name }}
            <v-chip
              color="primary"
              text
              x-small
              label
              outlined
              @click="open_zayavlenie(item)"
            >
              заявление
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="text-center">
              <v-menu offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                    Действия
                  </v-btn>
                </template>
                <v-list>
                  <div v-if="!is_awaiting_group">
                    <v-list-item v-if="!is_awaiting_group" @click="move_cons(item.id)">
                      <v-list-item-title>Вернуть в комнату ожидания</v-list-item-title>
                    </v-list-item>
                  </div>
                  <div v-if="is_awaiting_group">
                    <v-list-item
                      v-for="(gr, index) in groups"
                      :key="index"
                    >
                      <v-list-item-title @click="move_to_group(gr.id, item.id)">Перенести в группу
                        {{ gr.name }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-row>
    </v-row>

    <div class="caption mt-5 text-left">
      <!-- <a href="https://github.com/firelayer/board-template" target="_blank" class="font-weight-black">Open on GitHub!</a> -->
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card>
          <v-toolbar
            flat
            dark
            color="rgba(4,162,124, 1)"
          >
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Редактировани группы</v-toolbar-title>
            <v-spacer></v-spacer>

          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="group_number"
                    label="Номер группы*"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="group_name"
                    label="Название группы"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="program"
                    :items="all_edu_programs"
                    label="Программа*"
                    item-text="name"
                    return-object
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="status"
                    :items="statusesCut"
                    label="Статус*"
                    item-text="name"
                    return-object
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form"
                    :items="forms"
                    label="Форма*"
                    item-text="name"
                    return-object
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="price"
                    label="Стоимость*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="manager"
                    label="Менеджер*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="ed_hours"
                    label="Количество часов*"
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="menu_start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_start"
                        label="Дата начала*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_start"
                      @input="menu_start_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-menu
                    v-model="menu_end_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_end"
                        label="Дата окончания*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_end"
                      @input="menu_end_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <small>*Обязательные поля</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              label
              class="mr-10"
              @click="edit_group()"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div class="text-center">
      <v-bottom-sheet
        v-model="sheet"
        inset
      >
        <v-sheet
          class="text-center"
          height="200px"
        >
          <v-btn
            class="mt-6"
            text
            color="primary"
            @click="sheet = false"
          >
            закрыть
          </v-btn>
          <div v-if="zayavlenie" class="my-3">
            Налоговый консультант <b> {{ zayavlenie.last_name }} {{ zayavlenie.name }} {{ zayavlenie.middle_name }}</b>
            с номером аттестата: <b> {{ zayavlenie.attestat_number }}</b> (дата <b> {{ zayavlenie.date_issues }} </b>)
            <br>
            E-mail:
            <a :href="`mailto:${zayavlenie.email}`"> {{ zayavlenie.email }}
              <v-icon> mdi-link</v-icon>
            </a> <br>
            Телефон: {{ zayavlenie.phone }}
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
    <v-alert
      v-model="error_move_consultant"
      type="error"
      dismissible
    >
      Что-то пошло не так. Обратитесь к администратору.
    </v-alert>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import readXlsxFile from 'read-excel-file'
import { base_url } from '@/services/base_url'
import UploadService from '../../services/UploadFilesService'

export default {
  data() {
    return {
      headers: [
        {
          text: 'ФИО',
          align: 'start',
          sortable: false,
          value: 'fio'
        },
        { text: 'Номер аттестата', value: 'attestat_number' },
        { text: 'Дата выдачи', value: 'date_issues' },
        { text: 'Срок действия', value: 'date_end' },
        { text: '', value: 'action' }
      ],
      desserts: [
        {
          name: 'Группа 3',
          calories: 'активная',
          fat: 'зачислить',
          carbs: 24,
          protein: 4.0,
          iron: '1%'
        }

      ],
      unsubscribe: null,
      cards: [],
      sheet: false,
      lists: [{
        id: 0,
        label: 'Templates (Apps Presets)',
        description: 'Web apps fully integrated with Firebase (blog, chat, chrome extension, etc)'
      }],

      isLoadingSave: false,

      // add variables
      addDialog: false,
      addToList: {},
      newCardText: '',
      newCardName: '',
      // delete variables
      deleteDialog: false,
      cardToDelete: null,
      isLoadingDelete: false,
      group_consultants: [],
      all_group_consultants: [],
      consultants: [],
      selectedConsultant: '',
      current_group_id: this.$route.query.id,
      group_number: '',
      group: {},
      currentPage: 1,
      consultantsPerPage: 10,
      dialog: false,
      level: '',
      all_edu_programs: [],
      edu_programs: [],
      statuses: [],
      status: '',
      program: '',
      is_awaiting_group: false,
      org_id: null,
      zayavlenie: null,
      groups: [],
      form: '',
      forms: [
        { name: 'Онлайн', key: 'online' },
        { name: 'Офлайн', key: 'offline' },
        { name: 'Дневная', key: 'day' },
        { name: 'Комбинированная', key: 'combo' },
        { name: 'Вечерняя', key: 'evening' },
        { name: 'Дистанционная', key: 'distant' },
        { name: 'Выходной день', key: 'dayoff' }
      ],
      price: 0,
      manager: '',
      ed_hours: 0,
      menu_start_date: false,
      menu_end_date: false,
      date_start: null,
      date_end: null,
      group_name: '',
      excel_data: null,
      consultants_from_room: [],
      error_move_consultant: null,
      programFile: null
    }
  },
  computed: {
    ...mapState('app', ['user']),
    statusesCut() {
      return this.statuses.filter(
        (item, index, array) => ['nabor', 'wait', 'wait_high'].includes(item.slug)
      )
    }
  },
  mounted() {
    this.GET_GROUP_CONSULTANTS({ id: this.current_group_id }).then((response) => {
      this.group = response.group
      this.group_consultants = response.items
      this.group_number = response.group_number
      this.status = response.group.status
      this.program = response.group.program
      this.org_id = response.org_id
      this.price = response.group.value
      this.manager = response.group.manager
      this.ed_hours = response.group.ed_hours
      this.group_name = response.group.name
      this.form = response.group.form
      this.date_start = this.to_date(response.group.date_start).toISOString().substr(0, 10)
      this.date_end = this.to_date(response.group.date_end).toISOString().substr(0, 10)
      if (this.status === 'Ожидание обучения' || this.status === 'Ожидание обучения (повышение квалификации)') {
        this.get_groups()
        this.is_awaiting_group = true
      }
      this.getEducationPrograms()
    }).catch((error) => {
      console.log(error)
    })

    this.getConsultantsFromRoom()

    // this.GET_ALL_CONSULTANTS().then(response => {
    //   this.consultants = response.items
    // }).catch(error => {
    //   console.log(error)
    // })

    this.GET_STATUSES().then((response) => {
      this.statuses = response.items
    }).catch((error) => {
      console.log(error)
    })
  },
  beforeDestroy() {
  },
  methods: {
    ...mapActions('app', ['showError', 'showToast', 'showSuccess', 'GET_GROUP_CONSULTANTS', 'GET_ALL_CONSULTANTS',
      'MOVE_CONSULTANT_TO_GROUP', 'ADD_CONSULTANT_TO_GROUP', 'GET_PROGRAMS', 'GET_STATUSES', 'UPDATE_GROUP', 'MOVE_CONSULTANT',
      'GET_ORG_GROUPS', 'ADD_CONSULTANTS_EXCEL', 'GET_CONSULTANTS_FROM_ROOM', 'GET_GROUP_BY_ID']),
    filterObject(item, queryText, itemText) {
      return (
        item.attestat_number.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1 ||
        item.fio.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    open_zayavlenie(item) {
      this.sheet = true
      this.zayavlenie = item
    },
    getEducationPrograms() {
      this.GET_PROGRAMS({ id: localStorage.getItem('uid'), online: true })
        .then((response) => {
          this.all_edu_programs = response.items
          this.loading = false
        }).catch((error) => {
          console.log(error)
        })
    },
    email(email) {
      return `mailto:${zayavlenie.email}`
    },
    add_consultant() {
      this.ADD_CONSULTANT_TO_GROUP({
        consultant: this.selectedConsultant,
        group: this.current_group_id
      }).then((response) => {
        this.load_consultants()
        this.getConsultantsFromRoom()
        this.selectedConsultant = null
      }).catch((error) => {
        console.log(error)
      })
    },
    path(val) {
      location.href = val
    },
    path_new_window(val) {
      window.open(
        val,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    edit_group() {
      this.UPDATE_GROUP({
        id: this.current_group_id,
        program: this.program.id,
        status: this.status.id,
        form: this.form.key,
        value: this.price,
        manager: this.manager,
        ed_hours: this.ed_hours,
        date_start: this.date_start,
        date_end: this.date_end,
        number: this.group_number,
        name: this.group_name
      }).then(
        (response) => this.$router.go(this.$router.currentRoute)
      )
    },
    load_consultants() {
      this.GET_GROUP_CONSULTANTS({ id: this.current_group_id }).then((response) => {
        this.group_consultants = response.items
        this.group_number = response.group_number
        this.status = response.group.status
        this.program = response.group.program
        this.org_id = response.org_id
        this.price = response.group.value
        this.manager = response.group.manager
        this.ed_hours = response.group.ed_hours
        this.group_name = response.group.name
        this.form = response.group.form
        this.date_start = this.to_date(response.group.date_start).toISOString().substr(0, 10)
        this.date_end = this.to_date(response.group.date_end).toISOString().substr(0, 10)
        this.getEducationPrograms()
      }).catch((error) => {
        console.log(error)
      })
    },
    /**
     * Вернуть консультанта в комнату ожидания
     * @param consultant_id: int
     */
    move_cons(consultant_id) {
      /**
       * this.current_group_id: str -id группы, от которой нужно отсоединить
       */

      this.MOVE_CONSULTANT({ consultant_id: consultant_id, group_id: this.current_group_id }).then(
        (response) => {
          this.load_consultants()
          this.getConsultantsFromRoom()
        }).catch(
        (error) => this.error_move_consultant = true
      )
    },
    move_to_group(group_id, cons_id) {
      this.MOVE_CONSULTANT_TO_GROUP({ group_id: group_id, id: cons_id }).then((response) => {
        this.load_consultants()
      }).catch((error) => {
        console.log(error)
      })
    },
    get_groups() {
      this.GET_ORG_GROUPS({ id: this.org_id, level: 1 }).then((response) => {
        this.groups = this.groups.concat(response.items)
      }).catch((error) => {
        console.log(error)
      })
      this.GET_ORG_GROUPS({ id: this.org_id, level: 0 }).then((response) => {
        this.groups = this.groups.concat(response.items)
      }).catch((error) => {
        console.log(error)
      })
    },
    loadProgram(even) {
      console.log()
    },
    /**
     * Получает строки из файла excel
     * @param event - сам файл, который загружает пользователь
     */
    onFileChange(event) {
      if (event) {
        readXlsxFile(event).then(
          (rows) => this.excel_data = rows
        )
      }

    },
    add_consultants_xlsx() {
      if (this.excel_data) {
        this.ADD_CONSULTANTS_EXCEL({ data: this.excel_data, group: this.current_group_id }).then(
          (response) => {
            this.load_consultants()
            this.getConsultantsFromRoom()
          }
        ).catch((error) => {
          console.log(error)
        })
      }
    },
    to_date(strDate) {
      const parts = strDate.split('.')

      return new Date(parts[2], parts[1] - 1, parseInt(parts[0]) + 1)
    },
    /**
     * Получает список консультантов для этой организации из комнаты ожидания
     */
    getConsultantsFromRoom() {
      this.GET_CONSULTANTS_FROM_ROOM(this.current_group_id).then(
        (res) => this.consultants_from_room = res.items
      )
    },
    link_file(url) {
      return `${base_url()}${url}`
    },
    upload() {
      this.progress = 0
      UploadService.upload_program_file(
        this.programFile,
        this.group.id,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        }).then(
        (response) => {
          this.group = response.group
          this.programFile = null
        }).catch(
        () => {
          this.progress = 0
          this.programFile = null
        })

    }
  }
}
</script>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.8s;
}

.primary-color {
  color: #333;
}

.list-move {
  transition: transform 0.3s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.board {
  display: flex;
  overflow-x: scroll;

  .board-column {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 200px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 6px;

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-description {
      min-height: 80px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .board-group {
    margin-top: 16px;
    height: 100%;
  }

  .board-item {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    min-height: 60px;
  }

  .board-item-menu {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
</style>
