<template>
  <div>
    <h3 class="font-weight-black ml-1 b">Заявление на повышение квалификации</h3>
    <p class=" ml-1 b">
      <!-- Заполнение формы приводит к регистрации в инфо - ресурсе Палаты Налоговых Консультантов,<br>
      если вы ранее не проходили подготовку, система также автоматически сформирует заявление на обучение
      <br>
      <small>
        <v-icon small>mdi-timer</v-icon>
        время заполнения 5 минут</small> -->
    </p>
    <!--    <v-card v-if="profile && profile.proposal && profile.proposal.form.type==='high'" color="red lighten-3" class="mb-12 text-center" height="200px">-->
    <!--      <v-icon class="mt-5" color="alert" x-large>mdi-alert-circle</v-icon>-->
    <!--      <p class="ma-2">-->
    <!--        У вас уже есть заявка на повышение квалификации.-->
    <!--        <br> Создание новой заявки, удалит старую!-->
    <!--      </p>-->
    <!--    </v-card>-->
    <p v-if="!token" class=" ml-1 b">
      <small>
        <v-btn
          color="primary"
          dark
          small
          @click="dialog=true"
        >
          Войти
        </v-btn>
      </small>
    </p>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="350px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Авторизация</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-text-field
              v-model="auth_email"
              label="E-mail или номер аттестата"
              required
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Пароль"
              required
            ></v-text-field>

          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false; sign_in()"
          >
            Войти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-stepper
      v-model="e6"
      vertical
    >
      <template>
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          Введите номер аттестата
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-row class="pa-5">
            <v-col cols="4" class="pa-0">
              <v-subheader>Ваш номер аттестата</v-subheader>
            </v-col>
            <v-col cols="8" class="pa-0">
              <v-text-field
                v-model="form.attestat"
                label="Номер аттестата"
                dense
                outlined
                type="number"
              ></v-text-field>
              <p v-if="attestat_not_exist">
                Аттестат не найден, проверьте правильность ввода
                или обратитесь к администрации через форму связи справа внизу страницы
              </p>
            </v-col>
          </v-row>

          <v-btn
            color="primary"
            :disabled="!form.attestat"
            @click="CheckAttestatExiste()"
          >
            Далее
          </v-btn>
        </v-stepper-content>
        <v-stepper-step
          :complete="e6 > 2"
          step="2"
        >
          Персональные данные
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-row class="pa-5">
            <template v-for="field in fields">
              <v-col cols="4" class="pa-0">
                <v-subheader>{{ field.name }}</v-subheader>
              </v-col>
              <v-col cols="8" class="pa-0">

                <v-text-field
                  v-model="form[field.key]"
                  :label="field.label"
                  dense
                  outlined
                  :prefix="field.prefix"
                ></v-text-field>
              </v-col>
            </template>
          </v-row>

          <v-checkbox
            v-model="pers_data"
            :label="`Согласен на обработку персональных данных`"
          ></v-checkbox>

          <v-btn
            color="primary"
            @click="isPersonalIsValid() ? e6 += 1 : false"
          >
            Далее
          </v-btn>
          <v-btn text @click="e6 -= step_value()">
            Назад
          </v-btn>
        </v-stepper-content>
        <v-stepper-step
          :complete="e6 > 3"
          step="3"
        >
          <span>
            Выберите регион
          </span>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-sheet class="pl-5 pa-2">
            <p>Выберите регион в котором планируете пройти повышение кваллификации?</p>
            <v-autocomplete
              v-model="form.region"
              :items="regions"
              outlined
              item-text="name"
              item-value="id"
              style="max-width:500px"
              label="Регион"
            ></v-autocomplete>

          </v-sheet>
          <v-btn
            color="primary"
            :disabled="form.region==null"
            @click="e6 += step_value()"
          >
            Далее
          </v-btn>
          <v-btn text @click="e6 -= 1">
            Назад
          </v-btn>
        </v-stepper-content>
        <v-stepper-step
          :complete="e6 > 4"
          step="4"
        >
          Образовательная организация
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-autocomplete
            v-model="form.org"
            auto-select-first
            :items="orgs"
            item-text="name"
            item-value="id"
            outlined
            style="max-width:500px"
            class="ml-5 mt-2"
            label="Организация"
          ></v-autocomplete>

          <v-btn
            color="primary"
            :disabled="form.org === null"
            @click="e6 = 5"
          >
            Далее
          </v-btn>
          <v-btn text @click="e6 -= 1">
            Назад
          </v-btn>
        </v-stepper-content>
        <v-stepper-step
          v-if="!questions.previous"
          :complete="e6 > 5"
          step="5"
          disabled
        >
          Программа обучения
        </v-stepper-step>

        <v-stepper-content step="5">
          <v-alert
            v-if="form.online"
            icon="mdi-alert"
            class="ma-2"
            prominent
            text
            type="primary"
          >
            В данной организации в настоящее время не заявлен набор группы на повышение кваллификации. Если вы готовы ожидать некоторое время, нажмите "да".
          </v-alert>
          <v-autocomplete
            v-if="!form.online"
            v-model="form.group"
            :items="org_groups"
            item-text="name"
            item-value="id"
            outlined
            style="max-width:500px"
            class="ml-5 mt-2"
            label="Группа"
          ></v-autocomplete>

          <v-btn
            color="primary"
            @click="e6 = 7; sendProposal()"
          >
           Да, подать заявление
          </v-btn>

          <v-btn v-if="form.online" text @click="e6 -= 2; form.online=false">
            Назад
          </v-btn>

          <v-btn v-else text @click="e6 -= 1">
            Назад
          </v-btn>
          <v-container>
            <small>
              Вместо обучения в группах вы можете выбрать повышение квалификации
              <router-link
                v-if="profile && profile.user.status.slug==='need_high'"
                :to="{ name: 'consultant' }"
                class="light-blue--text"
              >другими способами</router-link>.
            </small>
          </v-container>

        </v-stepper-content>

        <v-stepper-step step="7">
          Готово
        </v-stepper-step>

        <v-stepper-content step="7">
          <v-card
            color="grey lighten-3"
            class="mb-12 text-center"
            height="200px"
          >
            <v-icon class="mt-5" color="success " x-large>mdi-check-circle</v-icon>
            <p class="ma-2">
              Ваше заявление принято, на указанный e-mail отправлена инструция для входа.
              <br>Запомните данные - они будут использоваться для входа в Инфо-ресурс
            </p>
          </v-card>

          <v-btn @click="path('/login/')">
            Войти в личный кабинет
          </v-btn>
        </v-stepper-content>
        <v-card v-if="error_after_send_proposal" color="red lighten-3" class="mb-12 text-center" height="200px">
          <v-icon class="mt-5" color="alert" x-large>mdi-alert-circle</v-icon>
          <p class="ma-2">
            {{ error_after_send_proposal }}
          </p>
          <v-btn @click="changeAttestat()">
            изменить данные
          </v-btn>
        </v-card>

      </template>

    </v-stepper>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import router from '@/router'

export default {
  data() {
    return {
      e6: 1,
      type1: false,
      questions: {
        previous: false
      },
      valid: false,
      form: {
        educated: true,
        region: null,
        org: null,
        program: null,
        attestat: null,
        name: null,
        online: false,
        group: null,
        email: null,
        last_name: null,
        middle_name: null,
        phone: null,
        address: null

        // staj: null
      },
      citt: '',
      dialog: false,
      pers_data: true,
      education_list: [
        'Высшее экономическое',
        'Высшее юридическое',
        'Высшее непрофильное',
        'Среднее профессиональное экономическое',
        'Среднее профессиональное юридичекое'
      ],
      regions: [],
      programs: [],
      org_groups: [],
      type2: true,
      token: null,
      auth_email: '',
      password: '',
      fields: [
        {
          name: 'E-mail', key: 'email', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
            digits: 7,
            regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
          },
          error: [
            (v) => !!v || 'Укажите адрес электронной почты',
            (v) => /.+@.+\..+/.test(v) || 'E-mail не прошел проверку'
          ]
        },
        {
          name: 'Фамилия', key: 'last_name', label: '', prefix: '', type: 'text',
          rule: {
            required: true
          },
          error: [
            (v) => !!v || 'Укажите фамилию'
          ]
        },
        {
          name: 'Имя', key: 'name', label: '', prefix: '', type: 'text',
          rule: {
            required: true
          },
          error: [
            (v) => !!v || 'Укажите Имя'
          ]
        },
        {
          name: 'Отчество', key: 'middle_name', label: '', prefix: '', type: 'text',
          rule: {
            required: true
          },
          error: [
            (v) => !!v || 'Укажите Отчество'
          ]
        },
        {
          name: 'Фактический адрес', key: 'address', label: '', prefix: '', requared: false, type: 'text',
          rule: {
            required: true
          },
          error: [
            (v) => !!v || 'Укажите Фактический адрес'
          ]
        },
        {
          name: 'Телефон', key: 'phone', label: '', prefix: '', type: 'text',
          rule: {
            required: true,
            digits: 7,
            regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$'
          },
          error: [
            (v) => !!v || 'Укажите ваш телефон'
          ]
        }
        // {
        //   name: 'Образование', key: 'education', label: '', prefix: '', type: 'select',
        //   rule: {
        //     required: true,
        //   },
        //   error: [
        //     v => !!v || 'Выберите образование',
        //   ]
        // },
        // {
        //   name: 'Стаж, лет', key: 'staj', label: '', prefix: '', type: 'number',
        //   rule: {
        //     required: true,
        //     digits: 7,
        //   },
        //   error: [
        //     v => !!v || 'Укажите стаж',
        //   ]
        // },
      ],
      orgs: [],
      org_id: Number(this.$route.query.id) || 0,
      group_id: Number(this.$route.query.group) || 0,
      error_after_send_proposal: false,
      attestat_not_exist: 0
    }
  },
  computed: {
    ...mapState('app', ['user', 'profile'])
  },
  watch: {
    form(val) {
      this.$refs.form.validate()
    },
    e6(val) {
      console.log(val)
      // if (val === 2 && this.attestat_not_exist) {
      //   console.log('ЗЕСЬ')
      //   this.e6 -= 1
      // }
      //   загружаем список организаций, если обучается впервые
      this.form['online'] = false
      if (this.form.org) {
        this.form['group'] = null
        this.loadOrgGroups(this.form.org)
      }
      if (this.form.region) {
        this.loadOrgs()
      }
    }
  },
  mounted() {
    this.loadConsultant()
    this.GET_REGIONS()
      .then((res) => {
        this.regions = res.items
      })
  },
  methods: {
    ...mapActions('app', ['GET_REGIONS', 'GET_ORGS',
      'GET_PROGRAMS', 'SIGN_UP_CONSULTANT', 'SIGN_IN', 'GET_INFO_PROFILE', 'GET_ORG_GROUPS',
      'CREATE_EDU_PROPOSAL', 'CHECK_ATTESTAT_EXIST']),
    /**
     * Проверяет заполненность персональных данных в форме
     * Не дает 100% защиту, есть косяки несущественные
     * @returns {boolean}
     */
    isPersonalIsValid() {
      const f = this.form
      const personal = [f['email'], f['last_name'], f['name'], f['middle_name'], f['phone'], f['address']]

      for (const key of personal) {
        if (!key) {
          return false
        }
      }

      return true
    },
    /**
     * Запрашивает профиль консультанта
     * Если есть аттестат -> заполняет ШАГ 1 и ШАГ 2(заполнится сам) -> ШАГ 3
     * Если нет аттестата -> ШАГ 2 (заполнится все равно)
     * Если аноним, то профиль не заполнится
     */
    loadConsultant() {
      if (localStorage.getItem('token')) {
        this.GET_INFO_PROFILE()
          .then((response) => {
            if (response) {
              this.token = true
              this.fillFormPersonal(this.profile.user)
              console.log(this.profile.user.region)
              if (this.profile.user.region.id === 2) {
                this.form.region = 2
                this.e6 = 4
              }
            }
          })
      }
    },
    /**
     * Заполняет форму
     * @param user - персональные данные пользователя
     */
    fillFormPersonal(user) {
      this.form['name'] = user.name
      this.form['middle_name'] = user.middle_name
      this.form['address'] = user.address
      this.form['last_name'] = user.last_name
      this.form['email'] = user.email
      this.form['phone'] = user.phone
      this.form['attestat'] = user.attestat_number
    },
    sign_in() {
      this.SIGN_IN({ email: this.auth_email, password: this.password })
        .then((response) => {
          this.loadConsultant()
        })
        .catch((error) => {
          console.log(error)
          alert('Произошла ошибка при входе')
        })

    },
    signup(data) {
      this.SIGN_UP_CONSULTANT(data)
        .then((res) => {
          // this.programs = res.items
        })
        .catch((e) => {
          this.e6 -= 1
          alert(e)
        })
    },
    /**
     * Список всех групп повышения квалификации со статусом "Набор" для конкретной организации
     * @param id - id организации
     */
    loadOrgGroups(id) {
      this.GET_ORG_GROUPS({ id: id, level: this.form.educated, short: 1 })
        .then((res) => {
          const groups = res.items

          if (groups.length === 0) {
            this.form.online = true
          }
          groups.forEach((group) => {
            group.name = group.program__name + ', начало: ' + new Date(group.date_start).toLocaleDateString('ru')
          })
          this.org_groups = groups

          if (this.group_id && res.items.find((item) => item.id === this.group_id)) {
            this.form.group = this.group_id
          }
        })
    },
    load_programs() {
      this.GET_PROGRAMS({ id: this.form.org, online: this.form.online, level: 'levelup' })
        .then((res) => {
          this.programs = res.items

        })
    },
    /**
     * Загружает организации указанного региона
     * Ничего не знает об организации, с которой был переход на эту форму
     * Если нет организаций -> ШАГ 5
     */
    loadOrgs() {
      this.GET_ORGS({ id: this.form.region })
        .then((res) => {
          this.orgs = res.items
          if (res.items.length === 0) {
            this.form.online = true
            this.e6 = 5
          } else {
            if (this.org_id && res.items.find((item) => item.id === this.org_id)) {
              this.form.online = false
              this.form.org = this.org_id
            }
          }
        })
    },
    step_value() {
      if (this.questions.previous) return 2
      else return 1
    },
    path(val) {
      location.href = val
    },
    sendProposal() {
      if (1) {
        this.CREATE_EDU_PROPOSAL({ form: this.form }).then(
          (res) => {
            this.e6 = 7
            setTimeout(router.push({ name: 'docs' }), 5000)
          } ).catch(
          (error) => {
            const _httpStatus = error.response.status

            if ( _httpStatus === 401 || _httpStatus === 400) {
              this.error_after_send_proposal = error.response.data.message
            }
            this.e6 -= 1
          })
      }
    },
    changeAttestat() {
      this.e6 = 1
      this.error_after_send_proposal = false
    },
    /**
     * Проверка, что аттестат существует
     * Если есть аттестат e6 -> +1, нет -> 0
     * @returns {undefined}
     * @constructor
     */
    async CheckAttestatExiste() {
      this.e6 = 1
      // const response = await this.CHECK_ATTESTAT_EXIST(this.form.attestat)

      // this.attestat_not_exist = response.result ? 0 : 1
      // response.result ? this.fillFormPersonal(response.user) : false
      // this.e6 += this.attestat_not_exist ? 0 : 1
    }
  }
}
</script>

<style lang="scss" scoped>
.b {
  color: #333 !important;
}
</style>
