export function parserDate(date, type) {
  let getYear = null;
  let getMonth = null;
  let getDay = null;
  if (type) {
    getDay = Number(date.slice(3, 5));
    getMonth = Number(date.slice(0, 2) - 1);
    getYear = Number(date.slice(6));
  } else {
    getYear = Number(date.slice(0, 4));
    getMonth = Number(date.slice(5, 7) - 1);
    getDay = Number(date.slice(8));
  }

  const result = new Date(getYear, getMonth, getDay);
  result.setHours(15);
  result.setMinutes(0);
  return result.toISOString();
}


export function parserString(date) {
  const idx = date.indexOf('T');
  return date.slice(0, idx);
}
