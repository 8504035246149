<template>
  <v-row>
    <v-col cols="9">
      <h4>Статус: Документы на проверке</h4>
      <v-alert prominent outlined color="#191654">
        <v-row align="center">
          <v-col class="grow">
            Загруженные документы находятся на проверке!
            <br>
            После проверки документов вам будет доступна оплата
            <span v-if="profile.proposal">{{ profile.proposal.form.type==='learning' ? 'обучения' : 'повышения квалификации' }}</span>.
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
    <v-col cols="3">
      <AttestatComponent></AttestatComponent>
    </v-col>
  </v-row>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import AttestatComponent from '@/components/common/AttestatComponent'

export default {
  components: { AttestatComponent },
  data() {
    return {}
  },
  computed: {
    ...mapState("app", ["user", "profile", "user_files"]),
  },
  created() {
  },
  methods: {
    ...mapActions("app", [
      "showError",
      "showToast",
      "showSuccess",
      "GET_ZAYAVLEMIYE",
      "GET_INFO_PROFILE",
      "USER_FILES",
      "GET_SCHET_POVYSHENIE",
      "FINISH_UPLOAD",
    ]),
  },
};
</script>
